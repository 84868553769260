import React, { useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import { Typography, Stepper, Step, StepButton } from "@material-ui/core";

import AccCreDanPolRegistration from "./AccCreDanPolRegistration.js";
import AccCreDanPolAttendance from "./AccCreDanPolAttendance.js";
import AccCreDanPolCostumeFee from "./AccCreDanPolCostumeFee.js";
import AccCreDanPolAdditionalCostumeInformation from "./AccCreDanPolAdditionalCostumeInformation.js";
import AccCreDanPolDressCodes from "./AccCreDanPolDressCodes.js";

const useStyles = makeStyles({
  paper: {
    maxWidth: "-webkit-fill-available",
    minWidth: "-webkit-fill-available",
  },
  noMargin: {
    margin: "0px",
  },
});


export default function AccCreDancePoliciesStep(props){
  // styling
  const classes = useStyles();

  // function that returns the list of steps as an array
  function getSteps(){
    return ["Registration", "Attendance", "Costume Fee", "Additional Costume Information", "Dress Codes"];
  }

  // the number of steps
  const totalSteps = () => {
    return getSteps().length;
  };

  // state variable for what step the stepper is on
  const [activeStepState, setActiveStepState] = useState(() => {
    // if props.startAtFirstStep is true, set the activeStepState to 0
    if(props.startAtFirstStep === true){
      return totalSteps() - totalSteps();
    }
    // else, set the activeStepState to the final step of the stepper
    else{
      return totalSteps() - 1;
    }
  });

  // state variable for which steps from the stepper have been completed
  const [completedState, setCompletedState] = useState(new Set(props.danPolCompletedState));

  // stepper step labels
  const steps = getSteps(activeStepState);

  // function that return the content for each step
  function getStepContent(step){
    switch(step){
      // Tuition
      case 0:
        return <AccCreDanPolRegistration 
          functHandleComplete={handleComplete}
          functHandleBack={handleBack}
          nextStepButtonString={props.nextStepButtonString}
          previousStepButtonString={props.previousStepButtonString}
        />;
      // Annual Fees
      case 1:
        return <AccCreDanPolAttendance 
          functHandleComplete={handleComplete}
          functHandleBack={handleBack}
          nextStepButtonString={props.nextStepButtonString}
          previousStepButtonString={props.previousStepButtonString}
        />;
      // Withdrawing From Lessons
      case 2:
        return <AccCreDanPolCostumeFee 
          functHandleComplete={handleComplete}
          functHandleBack={handleBack}
          nextStepButtonString={props.nextStepButtonString}
          previousStepButtonString={props.previousStepButtonString}
        />;
      // Attendance
      case 3:
        return <AccCreDanPolAdditionalCostumeInformation 
          functHandleComplete={handleComplete}
          functHandleBack={handleBack}
          nextStepButtonString={props.nextStepButtonString}
          previousStepButtonString={props.previousStepButtonString}
        />;
      // Policies
      case 4:
        return <AccCreDanPolDressCodes 
          functHandleComplete={handleComplete}
          functHandleBack={handleBack}
          nextStepButtonString={props.nextStepButtonString}
          previousStepButtonString={props.previousStepButtonString}
        />;
      // default case
      default:
        return null;
    }
  }

  // check to see if a step is optional
  // (step) is a 0-based number that represents what step you're on
  // what you're matching it to is the steps you want to be optional
  // ex: return step === 0; means that the 0th step is optional (the first step)
  // ex: return false; means that no steps are optional
  const isStepOptional = (step) => {
    return false;
  };

  // to determine if the current step is the first step of the stepper
  const isFirstStep = () => {
    return activeStepState === totalSteps() - totalSteps();
  }

  // to determine if the current step is the last step of the stepper
  const isLastStep = () => {
    return activeStepState === totalSteps() - 1;
  }

  // step forward
  const handleNext = () => {
    // if we're at the last step, step the top level stepper forward 
    if(isLastStep() === true){
      props.functTopHandleNext();
    }
    // else,set the stepper forward
    else{
      setActiveStepState((prevActiveStepState) => prevActiveStepState + 1);
    }
  };

  // step backward
  const handleBack = () => {
    // if we're at the last step, step the top level stepper backward 
    if(isFirstStep() === true){
      props.functTopHandleBack();
    }
    // else, step this stepper backward
    else{
      setActiveStepState((prevActiveStepState) => prevActiveStepState - 1);
    }
  };

  // redundant?
  const handleStep = (step) => () => {
    setActiveStepState(step);
  };

  // handle completing a step
  const handleComplete = () => {
    const newCompleted = new Set(completedState);
    newCompleted.add(activeStepState);
    setCompletedState(newCompleted);

    // store the completed steps in the top level as well
    props.setDanPolCompletedState(newCompleted);

    // call handleNext() to step forward
    handleNext();
  };

  // check if a step has been completed
  const isStepComplete = (step) => {
    return completedState.has(step);
  };



  // Steps 
  return (
    <div className={classes.root}>
      <Typography variant="h5">Dance Policies</Typography>
      <Stepper alternativeLabel activeStep={activeStepState}>
        {steps.map((label, index) => {
          const stepProps = {};
          const buttonProps = {};
          if (isStepOptional(index)) {
            buttonProps.optional = <Typography variant="caption">Optional</Typography>;
          }
          return (
            <Step key={label} {...stepProps}>
              <StepButton
                onClick={handleStep(index)}
                completed={isStepComplete(index)}
                {...buttonProps}
              >
                {label}
              </StepButton>
            </Step>
          );
        })}
      </Stepper>
      {getStepContent(activeStepState)}
    </div>
  );
}
