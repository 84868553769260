import React, { useState } from "react";
import { Link, Redirect } from "react-router-dom";
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import TextField from "@material-ui/core/TextField";
import Container from "@material-ui/core/Container";
import Paper from "@material-ui/core/Paper";
import { Button } from "@material-ui/core";
import Axios from "axios";

export default function EditAccountPage(props) {
    // Props that are required to use this page correctly
    // Props needed: all account data to be edited, pages to load on button click, and to 'save' the edited data
    const { accountData, functModifyAppState } = props;
    const { fname, lname, cellPhone, homePhone, email, street, city, state, zip, birthdate } = accountData;

    const [firstName, setFname] = useState(() => {
        return fname;
    });
    const fnameChange = (e) => {
        setFname(e.target.value);
    };

    const [lastName, setLname] = useState(() => {
        return lname;
    });
    const lnameChange = (e) => {
        setLname(e.target.value);
    };

    const [phoneCell, setCellPhone] = useState(() => {
        return cellPhone;
    });
    const cellPhoneChange = (e) => {
        setCellPhone(e.target.value);
    };

    const [phoneHome, setHomePhone] = useState(() => {
        return homePhone;
    });
    const homePhoneChange = (e) => {
        setHomePhone(e.target.value);
    };

    const [mail, setEmail] = useState(() => {
        return email;
    });
    const emailChange = (e) => {
        setEmail(e.target.value);
    };
    const [address, setStreet] = useState(() => {
        return street;
    });
    const streetChange = (e) => {
        setStreet(e.target.value);
    };
    const [town, setCity] = useState(() => {
        return city;
    });
    const cityChange = (e) => {
        setCity(e.target.value);
    };
    const [biggerTown, setState] = useState(() => {
        return state;
    });
    const stateChange = (e) => {
        setState(e.target.value);
    };
    const [zap, setZip] = useState(() => {
        return zip;
    });
    const zipChange = (e) => {
        setZip(e.target.value);
    };
    // This is to be used for reference when doing date pickers
    // const [bDay, setBirthDate] = useState(() => {
    //     return birthdate;
    // });
    // const birthDateChange = (e) => {
    //     setBirthDate(e.target.value);
    // };

    const [isPageReadyToBeRedirected, setRedirectState] = useState(() => {
        return false;
    });
    const changeRedirectState = () => {
        setRedirectState(true);
    };

    const [linkToRedirectTo, setLink] = useState(() => {
        return null;
    });
    const changeLink = (lin) => {
        setLink(lin);
    };

    const checkSave = () => {
        if (firstName !== "" && lastName !== "" && mail !== "" && phoneCell !== "") {
            attemptSave();
        } else {
            console.log("Fill out the fields!!!");
        }
    };

    const attemptSave = () => {
        Axios({
            method: "POST",
            url: "http://localhost:5000/editAccount",
            headers: {
                "Content-Type": "application/json",
            },
            data: {
                id: accountData.ID,
                fname: firstName,
                lname: lastName,
                cellPhone: phoneCell,
                homePhone: phoneHome,
                email: mail,
                street: address,
                city: town,
                state: biggerTown,
                zip: zap,
            },
        })
            .then((res) => {
                //This is where the respones goes if necessary

                let acctD = res.data.userData[0];
                acctD.birthdate = acctD.birthdate.toString().substring(0, 10);
                acctD.accountType = "Customer";
                functModifyAppState({ accountData: acctD });

                changeRedirectState();
                changeLink("/account");
            })
            .catch((error) => {
                console.log(error.data);
            });
    };

    const getError = (val) => {
        if (val === "") {
            return true;
        } else {
            return false;
        }
    };
    const getHelper = (val) => {
        if (val === "") {
            return "Required Field";
        } else {
            return "";
        }
    };

    if (isPageReadyToBeRedirected === true && linkToRedirectTo != null) {
        return <Redirect to={linkToRedirectTo} />;
    }

    return (
        <div>
            <Container style={{ paddingTop: "6vh", paddingBottom: "4vh" }} maxWidth="md">
                <Paper
                    elevation={10}
                    style={{
                        position: "relative",
                        padding: "4vw",
                        paddingBottom: "5vh",
                    }}
                >
                    <Typography variant="h6" gutterBottom>
                        Edit Account Details
                    </Typography>
                    <br></br>
                    <Grid container spacing={3} gutterBottom>
                        <Grid item xs={12} sm={6}>
                            <TextField
                                variant="outlined"
                                id="firstName"
                                name="firstName"
                                label="First name"
                                required
                                value={firstName}
                                onChange={fnameChange}
                                fullWidth
                                autoComplete="given-name"
                                error={getError(firstName)}
                                helperText={getHelper(firstName)}
                            />
                        </Grid>
                        <Grid item xs={12} sm={6}>
                            <TextField
                                variant="outlined"
                                id="lastName"
                                name="lastName"
                                label="Last name"
                                required
                                value={lastName}
                                onChange={lnameChange}
                                fullWidth
                                autoComplete="family-name"
                                error={getError(lastName)}
                                helperText={getHelper(lastName)}
                            />
                        </Grid>
                        <Grid item xs={12} sm={6}>
                            <TextField
                                variant="outlined"
                                id="cellPhone"
                                name="cellPhone"
                                label="Cell"
                                required
                                value={phoneCell}
                                onChange={cellPhoneChange}
                                fullWidth
                                autoComplete="cell-phone"
                                error={getError(phoneCell)}
                                helperText={getHelper(phoneCell)}
                            />
                        </Grid>
                        <Grid item xs={12} sm={6}>
                            <TextField
                                variant="outlined"
                                id="homePhone"
                                name="homePhone"
                                label="Home Phone"
                                value={phoneHome}
                                onChange={homePhoneChange}
                                fullWidth
                                autoComplete="home-phone"
                            />
                        </Grid>
                        <Grid item xs={12} sm={6}>
                            <TextField
                                variant="outlined"
                                id="Email"
                                name="emailAddress"
                                label="Email"
                                required
                                value={mail}
                                onChange={emailChange}
                                fullWidth
                                autoComplete="email-address"
                                error={getError(mail)}
                                helperText={getHelper(mail)}
                            />
                        </Grid>
                        <Grid item xs={6}>
                            <TextField
                                variant="outlined"
                                id="address"
                                name="address"
                                label="Address"
                                value={address}
                                onChange={streetChange}
                                fullWidth
                                autoComplete="shipping address-line1"
                            />
                        </Grid>
                        <Grid item xs={12} sm={6}>
                            <TextField
                                variant="outlined"
                                id="city"
                                name="city"
                                label="City"
                                value={town}
                                onChange={cityChange}
                                fullWidth
                                autoComplete="shipping address-level2"
                            />
                        </Grid>
                        <Grid item xs={12} sm={6}>
                            <TextField
                                variant="outlined"
                                id="state"
                                name="state"
                                label="State/Province/Region"
                                value={biggerTown}
                                onChange={stateChange}
                                fullWidth
                            />
                        </Grid>
                        <Grid item xs={12} sm={6}>
                            <TextField
                                variant="outlined"
                                id="zip"
                                name="zip"
                                label="Zip / Postal code"
                                value={zap}
                                onChange={zipChange}
                                fullWidth
                                autoComplete="shipping postal-code"
                            />
                        </Grid>
                        {/* This is to be useed as refrence for doing date pickers
                            <Grid item xs={12} sm={6}>
                            <TextField
                                InputLabelProps={{ shrink: true }}
                                variant="outlined"
                                id="birthday"
                                name="birthday"
                                label="Birthday"
                                required
                                value={bDay}
                                onChange={birthDateChange}
                                fullWidth
                                autoComplete="birthday"
                                type="date"
                                error={getError(bDay)}
                                helperText={getHelper(bDay)}
                            />
                        </Grid> */}
                        <Grid item xs={12} sm={6} style={{ marginTop: "16px", height: "fit-content" }}>
                            <Link style={{ textDecoration: "none" }} to="/edit-password">
                                Edit Password
                            </Link>
                        </Grid>
                    </Grid>
                    <Grid item xs={12} style={{ paddingTop: "3vh" }}>
                        <Button variant="contained" color="secondary" style={{ width: "6vw" }} onClick={checkSave}>
                            Save
                        </Button>
                    </Grid>
                    <Grid item xs={12} style={{ paddingTop: "3vh" }}>
                        <Link style={{ textDecoration: "none" }} to="/account">
                            <Button variant="contained" color="secondary" style={{ width: "6vw" }}>
                                Cancel
                            </Button>
                        </Link>
                    </Grid>
                </Paper>
            </Container>
        </div>
    );
}
