import React, { Component } from "react";
import { Link } from "react-router-dom";
import { Button, Typography, Grid, Paper, Container } from "@material-ui/core";

export default class SignedOutPage extends Component {
  render() {
    return (
      <div>
        <Container style={{ paddingTop: "6vh", paddingBottom: "5vh" }} maxWidth="md">
          <Paper
            elevation={10}
            style={{
              position: "relative",
              padding: "4vw",
            }}
          >
            <Grid container spacing={4}>
              <Grid item xs={12}>
                <Typography variant="h4">Goodbye, we hope to see you again soon!</Typography>
              </Grid>
              <Grid item xs={12}>
                <Typography variant="h5">You have been successfully signed out.</Typography>
              </Grid>
              <Grid item xs={12}>
                <Link style={{ textDecoration: "none" }} to="/welcome">
                  <Button variant="contained" color="secondary">
                    Click here to be taken to the Welcome Page
                  </Button>
                </Link>
              </Grid>
            </Grid>
          </Paper>
        </Container>
      </div>
    );
  }
}
