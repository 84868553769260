import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import { Grid, Typography, Paper, Button } from "@material-ui/core/";



const useStyles = makeStyles({
  paper: {
    maxWidth: "-webkit-fill-available",
    minWidth: "-webkit-fill-available",
  },
  noMargin: {
    margin: "0px",
  },
});

export default function AccCreGenPolPolicies(props){
  // styling
  const classes = useStyles();

  // strings that hold the text content - this is to keep the return statement clean
  const stringOne = "McLaughlin Studios believes that all students, regardless of age or level, are able to enrich and empower their minds through the study of the arts.";

  const stringTwo = "All enrolled students and families of McLaughlin Studios agree to abide by the following policies:";

  const stringThree = "Payment of Tuition:";
  const stringFour = "Tuition payments may be made with a recurring credit or debit card payment, via our online invoices, or in person with the McLaughlin Studios office staff.";

  const stringFive = "Studio Holidays:";
  const stringSix = "There are no lessons the week of Thanksgiving, two weeks of winter break, the week of Spring break, Fourth of July, Labor Day, and Memorial Day. All students will receive a minimum of 44 lessons per year. Tuition is calculated to include holidays.";

  const stringSeven = "Parent's Responsibility and Awareness of Dates and Events:";
  const stringEight = "It is the responsibility of the parents or adult student to be aware of all studio activities, included, but not limited to: viewing days, recitals, extra classes, and dates the studio is open or closed. The studio will post all such notices prominently around the studio, as well as sending out emails. It is the responsibility of the parents or adult students to inform the studio of any changes in contact information.";

  const stringNine = "Care of Students/Pick Up and Drop Off:";
  const stringTen = "Nobody is available to watch your students before and after lessons. Please arrange pick up and drop off accordingly or remain in the building for the duration of your child's lessons. Children must be supervised in the waiting room. McLaughlin Studios is not responsible for the safety of your child before or after their regularly scheduled lesson times. This includes, but is not limited to: early drop off, late pick up, or arriving at an incorrect time.";

  const stringEleven = "Injuries:";
  const stringTwelve = "Parents, legal guardians of minors, students, and adult students waive the right to any legal action for injury sustained on studio property resulting from normal lesson activity or any other activity conducted by the students before, during, or after lesson time.";
  
  const stringThirteen = "Practice Time:";
  const stringFourteen = "Parents and adult students are responsible for scheduling regular practice sessions at home. Teachers will provide a list of what students are expected to practice during the week. If you have questions about practicing, please discuss the issues with your teacher.";
  
  const stringFifteen = "Photo/Video Release:";
  const stringSixteen = "McLaughlin Studios is hereby granted permission to take photographs and videos of students to use in online and promotional materials. Permission is also granted for the studio to copyright such material in its name. Last names are never published. If you would not like for your child's pictures to be used, please inform us in writing.";

  const stringSeventeen = "I have read and understand the above policies and agree to abide by them.";

  return (
    <Grid container direction="row" justify="space-evenly" alignItems="center">
      <Grid item xs={false} sm={false} md={false} lg={2} />
      <Grid
        item
        container
        xs={12}
        sm={12}
        md={12}
        lg={8}
        spacing={5}
        direction="column"
        justify="center"
        alignItems="center"
        style={{ paddingTop: "6vh" }}
        classes={{ "spacing-xs-5": classes.noMargin }}
      >
        <Paper elevation={5} style={{ padding: " 5vh 10vw" }}>
          <Grid item>
            <Typography variant="h4">Policies</Typography>
          </Grid>
          <Grid container spacing={3} classes={{ "spacing-xs-3": classes.noMargin }}>
            <Grid item xs={12}>
              <Typography>{stringOne}</Typography>
            </Grid>
            <Grid item xs={12}>
              <Typography>{stringTwo}</Typography>
            </Grid>
            <Grid item xs={12}>
              <Typography variant="h6">{stringThree}</Typography>
              <Typography>{stringFour}</Typography>
            </Grid>
            <Grid item xs={12}>
              <Typography variant="h6">{stringFive}</Typography>
              <Typography>{stringSix}</Typography>
            </Grid>
            <Grid item xs={12}>
              <Typography variant="h6">{stringSeven}</Typography>
              <Typography>{stringEight}</Typography>
            </Grid>
            <Grid item xs={12}>
              <Typography variant="h6">{stringNine}</Typography>
              <Typography>{stringTen}</Typography>
            </Grid>
            <Grid item xs={12}>
              <Typography variant="h6">{stringEleven}</Typography>
              <Typography>{stringTwelve}</Typography>
            </Grid>
            <Grid item xs={12}>
              <Typography variant="h6">{stringThirteen}</Typography>
              <Typography>{stringFourteen}</Typography>
            </Grid>
            <Grid item xs={12}>
              <Typography variant="h6">{stringFifteen}</Typography>
              <Typography>{stringSixteen}</Typography>
            </Grid>
            <Grid item xs={12}>
              <Typography variant="h6">{stringSeventeen}</Typography>
            </Grid>
            <Grid item xs={12} sm={6}>
              <Button variant="contained" color="secondary" onClick={props.functHandleBack}>
                {props.previousStepButtonString}
              </Button>
            </Grid>
            <Grid item xs={12} sm={6}>
              <Button variant="contained" color="secondary" onClick={props.functHandleComplete}>
                {props.nextStepButtonString}
              </Button>
            </Grid>
          </Grid>
        </Paper>
      </Grid>
      <Grid item xs={false} sm={false} md={false} lg={2} />
    </Grid>
  );
}
