import React, { useState, useEffect, useRef } from "react";
import { Redirect } from "react-router-dom";
import { Button, Typography, makeStyles, Paper, Container, TextField, Select, MenuItem, InputLabel, FormControl, Grid } from "@material-ui/core";
import Axios from "axios";

const addAnAccountContactStyles = makeStyles((theme) => ({
    root: {
        height: 100,
        width: 50,
    },
}));

export default function AddAnAccountContactPage(props) {
    const { accountData, functModifyAppState } = props;

    const relations = [
        { key: "Family", value: "Family" },
        { key: "Friend", value: "Friend" },
    ];

    // This functions hadels key presses
    const useKey = (key, cb) => {
        const callBackRef = useRef(cb);
        useEffect(() => {
            callBackRef.current = cb;
        });
        useEffect(() => {
            const handle = (event) => {
                if (event.code === key) {
                    callBackRef.current(event);
                }
            };
            document.addEventListener("keypress", handle);
            return () => document.removeEventListener("keypress", handle);
        }, [key]);
    };
    // Callback Function
    const handleEnter = () => {
        onAddPress();
    };
    // Handle enter
    useKey("Enter", handleEnter);

    // State for the 3 fields
    const [fname, setFname] = useState(() => {
        return "";
    });
    const changeFname = (e) => {
        changeError(false);
        setAttempted(false);
        setFname(e.target.value);
    };

    const [lname, setLname] = useState(() => {
        return "";
    });
    const changeLname = (e) => {
        changeError(false);
        setAttempted(false);
        setLname(e.target.value);
    };

    const [email, setEmail] = useState(() => {
        return "";
    });
    const changeEmail = (e) => {
        changeError(false);
        setAttempted(false);
        setEmail(e.target.value);
    };

    const [phoneCell, setCellPhone] = useState(() => {
        return "";
    });
    const changeCellPhone = (e) => {
        changeError(false);
        setAttempted(false);
        setCellPhone(e.target.value);
    };

    const [phoneOther, setOtherPhone] = useState(() => {
        return "";
    });
    const changeOtherPHone = (e) => {
        changeError(false);
        setAttempted(false);
        setOtherPhone(e.target.value);
    };

    const [relation, setRelation] = useState(() => {
        return "";
    });
    const changeRelation = (e) => {
        changeError(false);
        setAttempted(false);
        setRelation(e.target.value);
    };

    // Redirect ready?
    const [isPageReadyToBeRedirected, setRedirectState] = useState(() => {
        return false;
    });
    const changeRedirectState = () => {
        setRedirectState(true);
    };
    // Redirect Location
    const [linkToRedirectTo, setLink] = useState(() => {
        return null;
    });
    const changeLink = (lin) => {
        setLink(lin);
    };

    // Handler for Cancel Press
    const onCancelPress = () => {
        changeRedirectState();
        changeLink("/account");
    };

    // Handler for confirm button press
    const onAddPress = () => {
        setAttempted(true);
        if (fname !== "" && lname !== "" && phoneCell !== "" && relation !== "") {
            addContact();
        } else {
            changeError(true);
            console.log("Entry error");
        }
    };

    const addContact = () => {
        Axios({
            method: "POST",
            url: "http://localhost:5000/addContact",
            headers: {
                "Content-Type": "application/json",
            },
            data: {
                userid: accountData.ID,
                fname: fname,
                lname: lname,
                email: email,
                cellPhone: phoneCell,
                homePhone: phoneOther,
                relation: relation,
            },
        })
            .then((res) => {
                if (res.data.message === "success") {
                    console.log(res.data.message);
                    // The return data needs to be all the contacts under the account
                    functModifyAppState({ contacts: res.data.contacts });
                    changeRedirectState();
                    changeLink("/account");
                } else if (res.data.message === "Contact Not Added") {
                    console.log(res.data.message);
                }
            })
            .catch((error) => {
                console.log(error.data);
            });
    };

    // State to know if entries are in error
    const [inError, setInError] = useState(() => {
        return false;
    });
    const changeError = (bo) => {
        setInError(bo);
    };

    // Did the user try to Add a contact?
    const [attemptedToAdd, setAttempted] = useState(() => {
        return false;
    });

    // Get helper text for fields if in error
    const getHelper = (val) => {
        if (val === "" && attemptedToAdd && inError) {
            return "Required Field";
        } else {
            return "";
        }
    };
    // Set fields to be in error conditionally
    const getError = (val) => {
        if (val === "" && attemptedToAdd && inError) {
            return inError;
        } else if (attemptedToAdd && inError) {
            return inError;
        } else {
            return inError;
        }
    };

    // redirect if the page is ready to be redirected
    if (isPageReadyToBeRedirected === true && linkToRedirectTo != null) {
        return <Redirect to={linkToRedirectTo} />;
    }
    // render the page normally
    return (
        <div className={addAnAccountContactStyles.root}>
            <Container style={{ paddingTop: "6vh", paddingBottom: "5vh" }} maxWidth="sm">
                <Paper
                    elevation={10}
                    style={{
                        position: "relative",
                        padding: "4vw",
                    }}
                >
                    <Grid container spacing={2}>
                        <Grid item xs={12}>
                            <Typography>
                                <strong>ADD AN ACCOUNT CONTACT</strong>
                            </Typography>
                        </Grid>
                        <Grid item xs={12}>
                            <TextField
                                variant="outlined"
                                id="firstNameField"
                                name="firstName"
                                label="First Name"
                                fullWidth
                                autoComplete="off"
                                required
                                onChange={changeFname}
                                value={fname}
                                error={getError(fname)}
                                helperText={getHelper(fname)}
                            />
                        </Grid>
                        <Grid item xs={12}>
                            <TextField
                                variant="outlined"
                                id="lastNameField"
                                name="lastName"
                                label="Last Name"
                                fullWidth
                                autoComplete="off"
                                required
                                onChange={changeLname}
                                value={lname}
                                error={getError(lname)}
                                helperText={getHelper(lname)}
                            />
                        </Grid>
                        <Grid item xs={12}>
                            <TextField
                                variant="outlined"
                                id="cellPhone"
                                name="cellPhone"
                                label="Cell Phone"
                                placeholder="(000) 000-0000"
                                fullWidth
                                autoComplete="off"
                                required
                                onChange={changeCellPhone}
                                value={phoneCell}
                                error={getError(phoneCell)}
                                helperText={getHelper(phoneCell)}
                            />
                        </Grid>
                        <Grid item xs={12} id="otherPhone">
                            <TextField
                                variant="outlined"
                                id="otherPhone"
                                name="otherPhone"
                                label="Other Phone Number"
                                fullWidth
                                autoComplete="off"
                                onChange={changeOtherPHone}
                                value={phoneOther}
                            />
                        </Grid>
                        <Grid item xs={12} id="email">
                            <TextField
                                variant="outlined"
                                id="email"
                                name="email"
                                label="Email"
                                fullWidth
                                autoComplete="off"
                                onChange={changeEmail}
                                value={email}
                            />
                        </Grid>
                        <Grid item xs={12}>
                            <TextField
                                required
                                id="relation"
                                select
                                label="Relationship"
                                value={relation}
                                onChange={changeRelation}
                                variant="outlined"
                                style={{ width: "10vw" }}
                            >
                                {relations.map((option) => (
                                    <MenuItem key={option.value} value={option.value}>
                                        {option.value}
                                    </MenuItem>
                                ))}
                            </TextField>
                        </Grid>
                        <Grid item xs={12}>
                            <Button variant="contained" color="default" onClick={onAddPress}>
                                Add Contact To Account
                            </Button>
                        </Grid>
                        <Grid item xs={12}>
                            <Button variant="contained" color="secondary" onClick={onCancelPress}>
                                Cancel
                            </Button>
                        </Grid>
                    </Grid>
                </Paper>
            </Container>
        </div>
    );
}
