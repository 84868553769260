import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import { Grid, Typography, Paper, Button } from "@material-ui/core/";


const useStyles = makeStyles({
  paper: {
    maxWidth: "-webkit-fill-available",
    minWidth: "-webkit-fill-available",
  },
  noMargin: {
    margin: "0px",
  },
});

export default function AccCreDanPolDressCodes(props){
  // styling
  const classes = useStyles();

  // strings that hold the text content - this is to keep the return statement clean
  const stringOne = "All classes:";
  const stringTwo = "Hair must be secured off the face and neck; hair must be in a bun for ballet classes. No Jewelry. No outside shoes. Do not wear your dance shoes outside, on the street, in the parking lot, etc. They are for the dance floor only!";

  const stringThree = "Creative Movement:";
  const stringFour = "Girls, please wear a light pink leotard, pink tights, and pink ballet shoes. Boys, please wear a light pink shirt, black jazz pants, and black ballet shoes.";

  const stringFive = "Pre-Ballet:";
  const stringSix = "Girls, please wear a light blue leotard, pink tights, and pink ballet shoes. Boys, please wear a light blue shirt, black jazz pants, and black ballet shoes.";

  const stringSeven = "Technical Ballet:";
  const stringEight = "Girls, please wear a solid color leotard, pink or black tight, and pink ballet shoes. Boys, please wear a solid color shirt, black jazz pants, and black ballet shoes.";

  const stringNine = "Ballet 1/2:";
  const stringTen = "Girls, please wear a navy blue leotard, pink tights, and pink ballet shoes. Ballet skirts and warm-ups are ok. Boys, please wear a navy blue shirt, black jazz pants, and black ballet shoes.";

  const stringEleven = "Ballet/Lyrical 3/4:";
  const stringTwelve = "Girls, please wear a black leotard, pink tights, and pink ballet shoes. Ballet skirts/shorts and warm-ups are ok. Boys, please wear a black shirt, black jazz pants, and black ballet shoes.";
  
  const stringThirteen = "Pre-pointe/Pointe:";
  const stringFourteen = "Please wear a solid color leotard, pink or black tights, and pink ballet shoes/pointe shoes. Ballet skirts/shorts and warm-ups are ok.";
  
  const stringFifteen = "Tap/Jazz:";
  const stringSixteen = "Girls, please wear a black leotard and tights or black dance pants with black leotard or tank top, black tap and jazz shoes. Shorts over leotard and tights is ok. Boys, please wear a black shirt, black jazz pants, and black jazz shoes.";

  const stringSeventeen = "Hip Hop:";
  const stringEighteen = "Please wear black jazz pants, t-shirt or tank top, and black dance sneakers.";
  
  const stringNineteen = "Musical Theatre Dance:";
  const stringTwenty = "Please wear a black leotard and tights or black dance pants with black leotard or tank top, and black tap and jazz shoes. Shorts over leotard and tights is ok.";
  
  const stringTwentyOne = "Adult Dance Classes:";
  const stringTwentyTwo = "Jazz class requires comfortable dance clothes to move in. Ballet class requires black leotard and pink tights; ballet skirts and warm-ups ok. Ballet shoes required for ballet class; jazz shoes required for jazz class.";
  
  const stringTwentyThree = "Dance Fit:";
  const stringTwentyFour = "Please wear comfortable fitness clothes to move in. No jeans. Dance Fit skirts optional.";
  
  const stringTwentyFive = "Stretch & Strength:";
  const stringTwentySix = "Please wear comfortable fitness clothes or leotard and tights of any color.";
  
  const stringTwentySeven = "I have read and understand the above policies and agree to abide by them.";

  return (
    <Grid container direction="row" justify="space-evenly" alignItems="center">
      <Grid item xs={false} sm={false} md={false} lg={2} />
      <Grid
        item
        container
        xs={12}
        sm={12}
        md={12}
        lg={8}
        spacing={5}
        direction="column"
        justify="center"
        alignItems="center"
        style={{ paddingTop: "6vh" }}
        classes={{ "spacing-xs-5": classes.noMargin }}
      >
        <Paper elevation={5} style={{ padding: " 5vh 10vw" }}>
          <Grid item>
            <Typography variant="h4">Dress Codes</Typography>
          </Grid>
          <Grid container spacing={3} classes={{ "spacing-xs-3": classes.noMargin }}>
            <Grid item xs={12}>
              <Typography variant="h6">{stringOne}</Typography>
              <Typography>{stringTwo}</Typography>
            </Grid>
            <Grid item xs={12}>
              <Typography variant="h6">{stringThree}</Typography>
              <Typography>{stringFour}</Typography>
            </Grid>
            <Grid item xs={12}>
              <Typography variant="h6">{stringFive}</Typography>
              <Typography>{stringSix}</Typography>
            </Grid>
            <Grid item xs={12}>
              <Typography variant="h6">{stringSeven}</Typography>
              <Typography>{stringEight}</Typography>
            </Grid>
            <Grid item xs={12}>
              <Typography variant="h6">{stringNine}</Typography>
              <Typography>{stringTen}</Typography>
            </Grid>
            <Grid item xs={12}>
              <Typography variant="h6">{stringEleven}</Typography>
              <Typography>{stringTwelve}</Typography>
            </Grid>
            <Grid item xs={12}>
              <Typography variant="h6">{stringThirteen}</Typography>
              <Typography>{stringFourteen}</Typography>
            </Grid>
            <Grid item xs={12}>
              <Typography variant="h6">{stringFifteen}</Typography>
              <Typography>{stringSixteen}</Typography>
            </Grid>
            <Grid item xs={12}>
              <Typography variant="h6">{stringSeventeen}</Typography>
              <Typography>{stringEighteen}</Typography>
            </Grid>
            <Grid item xs={12}>
              <Typography variant="h6">{stringNineteen}</Typography>
              <Typography>{stringTwenty}</Typography>
            </Grid>
            <Grid item xs={12}>
              <Typography variant="h6">{stringTwentyOne}</Typography>
              <Typography>{stringTwentyTwo}</Typography>
            </Grid>
            <Grid item xs={12}>
              <Typography variant="h6">{stringTwentyThree}</Typography>
              <Typography>{stringTwentyFour}</Typography>
            </Grid>
            <Grid item xs={12}>
              <Typography variant="h6">{stringTwentyFive}</Typography>
              <Typography>{stringTwentySix}</Typography>
            </Grid>
            <Grid item xs={12}>
              <Typography variant="h6">{stringTwentySeven}</Typography>
            </Grid>
            <Grid item xs={12} sm={6}>
              <Button variant="contained" color="secondary" onClick={props.functHandleBack}>
                {props.previousStepButtonString}
              </Button>
            </Grid>
            <Grid item xs={12} sm={6}>
              <Button variant="contained" color="secondary" onClick={props.functHandleComplete}>
                {props.nextStepButtonString}
              </Button>
            </Grid>
          </Grid>
        </Paper>
      </Grid>
      <Grid item xs={false} sm={false} md={false} lg={2} />
    </Grid>
  );
}
