import React from "react";
import { Link } from "react-router-dom";
import { makeStyles, withStyles } from "@material-ui/core/styles";
import Card from "@material-ui/core/Card";
import CardActions from "@material-ui/core/CardActions";
import CardContent from "@material-ui/core/CardContent";
import CardMedia from "@material-ui/core/CardMedia";
import Button from "@material-ui/core/Button";
import Typography from "@material-ui/core/Typography";
import Dialog from "@material-ui/core/Dialog";
import MuiDialogTitle from "@material-ui/core/DialogTitle";
import IconButton from "@material-ui/core/IconButton";
import CloseIcon from "@material-ui/icons/Close";
import DialogContent from "@material-ui/core/DialogContent";
import Slide from "@material-ui/core/Slide";

const useStyles = makeStyles({
  root: {
    maxWidth: 345,
  },
  media: {
    height: 140,
  },
});

const styles = (theme) => ({
  closeButton: {
    position: "absolute",
    right: theme.spacing(1),
    top: theme.spacing(1),
    color: theme.palette.grey[500],
  },
});

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

const DialogTitle = withStyles(styles)((props) => {
  const { children, classes, onClose, ...other } = props;
  return (
    <MuiDialogTitle disableTypography className={classes.root} {...other}>
      <Typography variant="h6">{children}</Typography>
      {onClose ? (
        <IconButton aria-label="close" className={classes.closeButton} onClick={onClose}>
          <CloseIcon />
        </IconButton>
      ) : null}
    </MuiDialogTitle>
  );
});

export default function Student(props) {
  const [open, setOpen] = React.useState(false);
  const handleClickOpen = () => {
    setOpen(true);
  };
  const handleClose = () => {
    setOpen(false);
  };

  const classes = useStyles();
  const { student } = props;
  const { fname, lname, phone, homePhone, email, address, city, state, zip, birthday } = student;
  return (
    <Card className={classes.root}>
      <CardMedia className={classes.media} image="/static/images/cards/contemplative-reptile.jpg" title="Contemplative Reptile" />
      <CardContent>
        <Typography gutterBottom variant="h5" component="h2">
          {fname}
        </Typography>
        <Typography variant="body2" color="textSecondary" component="p">
          Lizards are a widespread group of squamate reptiles, with over 6,000 species, ranging across all continents except Antarctica
        </Typography>
      </CardContent>
      <CardActions>
        <Link style={{ textDecoration: "none" }} onClick={handleClickOpen}>
          <Button size="small" color="primary">
            Request a Lesson/Class
          </Button>
        </Link>
        <Link style={{ textDecoration: "none" }} to="/edit-student">
          <Button size="small" color="primary" style={{ marginLeft: "auto" }}>
            Edit
          </Button>
        </Link>
        <Dialog onClose={handleClose} open={open} TransitionComponent={Transition} maxWidth>
          <DialogTitle id="googleForm" onClose={handleClose}>
            Request a Lesson/Class
          </DialogTitle>
          <DialogContent dividers className={useStyles.root}>
            <iframe
              src="https://docs.google.com/forms/d/e/1FAIpQLSepcCaTgP6qopbMIyG6kAwxprYuyg_-KxBhZSk6Retlg0UdOg/viewform?embedded=true"
              width="640"
              height="1250"
              frameborder="0"
              marginheight="0"
              marginwidth="0"
            >
              Loading…
            </iframe>
          </DialogContent>
        </Dialog>
      </CardActions>
    </Card>
  );
}
