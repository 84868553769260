import React, { useState, useEffect, useRef } from "react";
import { Redirect } from 'react-router-dom';
import Axios from "axios";
import { makeStyles } from "@material-ui/core/styles";
import { Grid, Typography, TextField, Paper, Button } from "@material-ui/core/";

const useStyles = makeStyles({
  paper: {
    maxWidth: "-webkit-fill-available",
    minWidth: "-webkit-fill-available",
  },
  noMargin: {
    margin: "0px",
  },
});

export default function AddStudentPage(props) {
  // state variables for redirecting the page only when ready
  // if the page is ready to be redirected (work successful | cancel work)
  // where to redirect to in each case
  const [pageRedirectState, setPageRedirectState] = useState({
    isPageReadyToBeRedirected: false,
    linkToRedirectTo: null
  });

  // the data from the text fields
  // firstName state variable
  const [firstName, setFirstNameState] = useState(() => {
    return "";
  });

  // firstName onChange handler
  const firstNameChanged = (e) => {
    changeError(false);
    setAttemptedToAddStudent(false);
    setFirstNameState(e.target.value);
  };

  // lastName state variable
  const [lastName, setLastNameState] = useState(() => {
    return "";
  });

  // lastName onChange handler
  const lastNameChanged = (e) => {
    changeError(false);
    setAttemptedToAddStudent(false);
    setLastNameState(e.target.value);
  };

  // birthday state variable
  const [birthday, setBirthdayState] = useState(() => {
    return "";
  });

  // birthday onChange handler
  const birthdayChanged = (e) => {
    changeError(false);
    setAttemptedToAddStudent(false);
    setBirthdayState(e.target.value);
  };

  // medicalConditions state variable
  const [medicalConditions, setMedicalConditionsState] = useState(() => {
    return "";
  });

  // medical conditions onChange handler
  const medicalConditionsChanged = (e) => {
    changeError(false);
    setAttemptedToAddStudent(false);
    setMedicalConditionsState(e.target.value);
  };
  
  // This functions handles key presses
  const useKey = (key, cb) => {
    const callBackRef = useRef(cb);
    useEffect(() => {
      callBackRef.current = cb;
    });
    useEffect(() => {
      const handle = (event) => {
        if (event.code === key) {
          callBackRef.current(event);
        }
      };
      document.addEventListener("keypress", handle);
      return () => document.removeEventListener("keypress", handle);
    }, [key]);
  };
  // Callback Function
  const handleEnter = () => {
    attemptToAddStudent();
  };
  // Handle enter
  useKey("Enter", handleEnter);

  // check to see if the text fields are filled out properly
  const checkFields = () => {
    // set that the user has attempted to add a student
    setAttemptedToAddStudent(true);

    // if the fields are filled out properly, return true
    if(firstName !== "" && lastName !== "" && birthday !== "" && medicalConditions !== ""){
      return true;
    }
    // else if the fields aren't filled out properly, display that there are errors and return false
    else {
      changeError(true);
      return false;
    }
  };

  // on cancel, return to accountPage
  const cancelAddingStudent = () => {
    // set that the page is ready to be redirected
    setPageRedirectState({
      isPageReadyToBeRedirected: true,
      linkToRedirectTo: "/account"
    });
  };

  // on continue
  // if all fields are filled properly add the student to the database
  const attemptToAddStudent = () => {
    if (checkFields() === true) {
      // add the newStudentData to the current account
      // DATABASE LADS DO THINGS HERE
      Axios({
        method: "POST",
        url: "http://localhost:5000/addStudent",
        headers: {
          "Content-Type": "application/json",
        },
        data: {
          userid: props.accountData.ID,
          fname: firstName,
          lname: lastName,
          birthdate: birthday,
          medical: medicalConditions
        },
      }).then((res) => {
        if (res.data.message === "success") {
          props.functModifyAppState({
            students: res.data.studentData
          });

          // set that the page is ready to be redirected
          setPageRedirectState({
            isPageReadyToBeRedirected: true,
            linkToRedirectTo: "/account"
          });
        }
        else {
          console.log("Add Student failed");
        }
      });
    }
    else {
      console.log("Enter fields correctly");
    }
  };

  // State to know if entries are in error
  const [inError, setInError] = useState(() => {
    return false;
  });
  const changeError = (bo) => {
    setInError(bo);
  };

  // Did the user try to Add a Student?
  const [attemptedToAddStudent, setAttemptedToAddStudent] = useState(() => {
    return false;
  });

  // Set fields to be in error conditionally
  const getError = (val) => {
    if (val === "" && attemptedToAddStudent && inError) {
      return true;
    } 
    else if (attemptedToAddStudent && inError) {
      return false;
    } 
    // else {
    //   return inError;
    // }
  };

  // Get helper text for fields if in error
  const getHelper = (val) => {
    if (val === "" && attemptedToAddStudent && inError) {
      return "Required Field";
    } else {
      return "";
    }
  };

  const classes = useStyles();

  // redirect if the page is ready to be redirected
  if (pageRedirectState.isPageReadyToBeRedirected === true && pageRedirectState.linkToRedirectTo != null) {
    return <Redirect to={pageRedirectState.linkToRedirectTo} />;
  }
  // render the page normally
  else {
    return (
      <Grid container direction="row" justify="space-evenly" alignItems="center">
        <Grid item xs={false} sm={false} md={false} lg={2} />
        <Grid
          item
          container
          xs={12}
          sm={12}
          md={12}
          lg={8}
          spacing={5}
          direction="column"
          justify="center"
          alignItems="center"
          style={{ paddingTop: "6vh" }}
          classes={{ "spacing-xs-5": classes.noMargin }}
        >
          <Paper elevation={5} style={{ padding: " 5vh 10vw" }}>
            <Grid item>
              <Typography>New Student Details</Typography>
            </Grid>
            <Grid container spacing={3} classes={{ "spacing-xs-3": classes.noMargin }}>
              <Grid item xs={12} sm={6}>
                <TextField 
                  required 
                  id="First Name" 
                  name="firstName" 
                  label="First name" 
                  value={firstName} 
                  onChange={firstNameChanged} 
                  fullWidth 
                  error={getError(firstName)}
                  helperText={getHelper(firstName)}
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                <TextField 
                  required 
                  id="Last Name" 
                  name="lastName" 
                  label="Last name" 
                  value={lastName} 
                  onChange={lastNameChanged} 
                  fullWidth 
                  error={getError(lastName)}
                  helperText={getHelper(lastName)}
                />
              </Grid>
              {/* <Grid item xs={12} sm={6}>
                <TextField 
                  required 
                  id="Birthday" 
                  name="birthday" 
                  label="Birthday" 
                  value={birthday} 
                  onChange={birthdayChanged} 
                  fullWidth 
                  error={getError(birthday)}
                  helperText={getHelper(birthday)}
                />
              </Grid> */}
              <Grid item xs={12} sm={6}>
                <TextField
                  InputLabelProps={{ shrink: true }}
                  variant="outlined"
                  id="birthday"
                  name="birthday"
                  label="Birthday"
                  required
                  value={birthday}
                  onChange={birthdayChanged}
                  fullWidth
                  autoComplete="birthday"
                  type="date"
                  error={getError(birthday)}
                  helperText={getHelper(birthday)}
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                <TextField 
                  required 
                  id="Medical Conditions" 
                  name="medicalConditions" 
                  label="Medical Conditions" 
                  placeholder='Type "None" if there is nothing to report'
                  value={medicalConditions} 
                  onChange={medicalConditionsChanged} 
                  fullWidth 
                  error={getError(medicalConditions)}
                  helperText={getHelper(medicalConditions)}
                />
              </Grid>
              <Grid item xs={12}>
                <Button style={{ width: "20vw" }} onClick={attemptToAddStudent}>
                  Add Student
                </Button>
              </Grid>
              <Grid item xs={12}>
                <Button style={{ width: "20vw" }} onClick={cancelAddingStudent}>
                  Cancel
                </Button>
              </Grid>
            </Grid>
          </Paper>
        </Grid>
        <Grid item xs={false} sm={false} md={false} lg={2} />
      </Grid>
    );
  }
}