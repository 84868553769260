import React, { useState, useEffect, useRef } from "react";
import { makeStyles } from "@material-ui/core/styles";
import { Grid, Typography, TextField, Paper, Button } from "@material-ui/core/";



const useStyles = makeStyles({
  paper: {
    maxWidth: "-webkit-fill-available",
    minWidth: "-webkit-fill-available",
  },
  noMargin: {
    margin: "0px",
  },
});

export default function AccCreAccInfStudentInformation(props){
  // styling
  const classes = useStyles();

  // the data from the text fields
  // firstName state variable
  const [firstName, setFirstNameState] = useState(() => {
    return props.studentInformationState.fname;
  });

  // firstName onChange handler
  const firstNameChanged = (e) => {
    changeError(false);
    setAttemptedToSaveData(false);
    setFirstNameState(e.target.value);
  };

  // lastName state variable
  const [lastName, setLastNameState] = useState(() => {
    return props.studentInformationState.lname;
  });

  // lastName onChange handler
  const lastNameChanged = (e) => {
    changeError(false);
    setAttemptedToSaveData(false);
    setLastNameState(e.target.value);
  };

  // birthday state variable
  const [birthday, setBirthdayState] = useState(() => {
    return props.studentInformationState.birthdate;
  });

  // birthday onChange handler
  const birthdayChanged = (e) => {
    changeError(false);
    setAttemptedToSaveData(false);
    setBirthdayState(e.target.value);
  };

  // medicalConditions state variable
  const [medicalConditions, setMedicalConditionsState] = useState(() => {
    return props.studentInformationState.medical;
  });

  // medicalConditions onChange handler
  const medicalConditionsChanged = (e) => {
    changeError(false);
    setAttemptedToSaveData(false);
    setMedicalConditionsState(e.target.value);
  };
  
  // This functions handles key presses
  const useKey = (key, cb) => {
    const callBackRef = useRef(cb);
    useEffect(() => {
      callBackRef.current = cb;
    });
    useEffect(() => {
      const handle = (event) => {
        if (event.code === key) {
          callBackRef.current(event);
        }
      };
      document.addEventListener("keypress", handle);
      return () => document.removeEventListener("keypress", handle);
    }, [key]);
  };
  // Callback Function
  const handleEnter = () => {
    handleComplete();
  };
  // Handle enter
  useKey("Enter", handleEnter);

  // check to see if the text fields are filled out properly
  // if they are then return FALSE to allow the "Accept & Continue" button to work
  // else return TRUE to disable the "Accept & Continue" button
  const checkIfIncomplete = () => {
    // if the fields are filled out properly, return false
    if(
      firstName !== "" &&
      lastName !== "" &&
      birthday !== "" &&
      medicalConditions !== ""
    ){
      return false;
    }
    // else if the fields aren't filled out properly, return true
    else {
      return true;
    }
  };

  // set the appropriate top level state with the data from this page
  const setStudentInformation = () => {
    // collect each state variable and combine them into a single object
    const studentInformationObj = {
      fname: firstName,
      lname: lastName,
      birthdate: birthday,
      medical: medicalConditions
    }

    // set the top level state to hold the data from this page
    props.functSetStudentInformationState(studentInformationObj);
  }

  // function for clearing the text fields of data
  const clearFields = () => {
    // call each setState() function and replace the content with the default empty value
    setFirstNameState("");
    setLastNameState("");
    setBirthdayState("");
    setMedicalConditionsState("");
  }

  // clear the data from the text fields before saving it and moving to the next step
  const handleSkip = () => {
    // clear the text fields
    clearFields();

    // set the top level state for this data
    setStudentInformation();

    // set that there IS NOT data to process
    props.functSetHasStudentInformationState(false);

    // step forward
    props.functHandleSkip();
  }

  // on Continue
  const handleComplete = () => {
    // set the top level state for this data
    setStudentInformation();

    // set that there IS data to process
    props.functSetHasStudentInformationState(true);

    // step forward
    props.functHandleComplete();
  }

  // State to know if entries are in error
  const [inError, setInError] = useState(() => {
    return false;
  });
  const changeError = (bo) => {
    setInError(bo);
  };

  // Did the user try to Add a Student?
  const [attemptedToSaveData, setAttemptedToSaveData] = useState(() => {
    return false;
  });

  // Set fields to be in error conditionally
  const getError = (val) => {
    if (val === "" && attemptedToSaveData && inError) {
      return true;
    } 
    else if (attemptedToSaveData && inError) {
      return false;
    } 
    // else {
    //   return inError;
    // }
  };

  // Get helper text for fields if in error
  const getHelper = (val) => {
    if (val === "" && attemptedToSaveData && inError) {
      return "Required Field";
    } else {
      return "";
    }
  };


  return (
    <Grid container direction="row" justify="space-evenly" alignItems="center">
      <Grid item xs={false} sm={false} md={false} lg={2} />
      <Grid
        item
        container
        xs={12}
        sm={12}
        md={12}
        lg={8}
        spacing={5}
        direction="column"
        justify="center"
        alignItems="center"
        style={{ paddingTop: "6vh" }}
        classes={{ "spacing-xs-5": classes.noMargin }}
      >
        <Paper elevation={5} style={{ padding: " 5vh 10vw" }}>
          <Grid item>
            <Typography variant="h4">Student Information</Typography>
            <Typography variant="h6">(optional)</Typography>
          </Grid>
          <Grid container spacing={3} classes={{ "spacing-xs-3": classes.noMargin }}>
            <Grid item xs={12} sm={6}>
              <TextField 
                required 
                id="First Name" 
                name="firstName" 
                label="First name" 
                value={firstName} 
                onChange={firstNameChanged} 
                fullWidth 
                error={getError(firstName)}
                helperText={getHelper(firstName)}
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <TextField 
                required 
                id="Last Name" 
                name="lastName" 
                label="Last name" 
                value={lastName} 
                onChange={lastNameChanged} 
                fullWidth 
                error={getError(firstName)}
                helperText={getHelper(firstName)}
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <TextField 
                required 
                id="Birthday" 
                name="birthday" 
                label="Birthday" 
                value={birthday} 
                onChange={birthdayChanged} 
                fullWidth 
                error={getError(firstName)}
                helperText={getHelper(firstName)}
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <TextField 
                required 
                id="Medical Conditions" 
                name="medicalConditions" 
                label="Medical Conditions" 
                value={medicalConditions} 
                onChange={medicalConditionsChanged} 
                fullWidth 
                error={getError(firstName)}
                helperText={getHelper(firstName)}
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <Button variant="contained" color="secondary" onClick={props.functHandleBack}>
                {props.previousStepButtonString}
              </Button>
            </Grid>
            <Grid item xs={12} sm={6}>
              <Button variant="contained" color="secondary" onClick={handleSkip}>
                {props.skipStepButtonString}
              </Button>
            </Grid>
            <Grid item xs={12} sm={6}>
              <Button disabled={checkIfIncomplete()} variant="contained" color="secondary" onClick={handleComplete}>
                {props.saveStepButtonString}
              </Button>
            </Grid>
          </Grid>
        </Paper>
      </Grid>
      <Grid item xs={false} sm={false} md={false} lg={2} />
    </Grid>
  );
}
