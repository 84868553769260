import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import { Grid, Typography, Paper, Button } from "@material-ui/core/";



const useStyles = makeStyles({
  paper: {
    maxWidth: "-webkit-fill-available",
    minWidth: "-webkit-fill-available",
  },
  noMargin: {
    margin: "0px",
  },
});

export default function AccCreGenPolTuition(props){
  // styling
  const classes = useStyles();

  // strings that hold the text content - this is to keep the return statement clean
  const stringOne = "Tuition is paid monthly at the beginning of each month. Tuition is the same, regardless of the number of lessons per month. Lessons are held year round and all students will receive a minimum of 44 lessons per year.";
  const stringTwo = "McLaughlin Studios accepts cash, check, and all major credit cards. Tuition is due by the 1st of the month and considered late by the 10th of the month. Payments made after the 10th of the month will result in a $25 late fee; all NSF checks and declined automatic payments will be charged an additional $25 fee.";
  const stringThree = "Private Lessons: 30min-$130/mo, 45min-$195/mo, 60min-$260/mo";
  const stringFour = "Duet Lessons: 30min-$86/mo, 45min-$130/mo, 60min-$174/mo";
  const stringFive = "Group Classes: 30min- $45/mo, 45min- $55/mo, 60min-$65/mo";


  return (
    <Grid container direction="row" justify="space-evenly" alignItems="center">
      <Grid item xs={false} sm={false} md={false} lg={2} />
      <Grid
        item
        container
        xs={12}
        sm={12}
        md={12}
        lg={8}
        spacing={5}
        direction="column"
        justify="center"
        alignItems="center"
        style={{ paddingTop: "6vh" }}
        classes={{ "spacing-xs-5": classes.noMargin }}
      >
        <Paper elevation={5} style={{ padding: " 5vh 10vw" }}>
          <Grid item>
            <Typography variant="h4">Tuition</Typography>
          </Grid>
          <Grid container spacing={3} classes={{ "spacing-xs-3": classes.noMargin }}>
            <Grid item xs={12}>
              <Typography>{stringOne}</Typography>
            </Grid>
            <Grid item xs={12}>
              <Typography>{stringTwo}</Typography>
            </Grid>
            <Grid item xs={12}>
              <Typography>{stringThree}</Typography>
            </Grid>
            <Grid item xs={12}>
              <Typography>{stringFour}</Typography>
            </Grid>
            <Grid item xs={12}>
              <Typography>{stringFive}</Typography>
            </Grid>
            <Grid item xs={12} sm={6}>
              <Button variant="contained" color="secondary" onClick={props.functHandleBack}>
                {props.previousStepButtonString}
              </Button>
            </Grid>
            <Grid item xs={12} sm={6}>
              <Button variant="contained" color="secondary" onClick={props.functHandleComplete}>
                {props.nextStepButtonString}
              </Button>
            </Grid>
          </Grid>
        </Paper>
      </Grid>
      <Grid item xs={false} sm={false} md={false} lg={2} />
    </Grid>
  );
}
