import React, { useState } from "react";
import { Link } from 'react-router-dom';
import { makeStyles } from "@material-ui/core/styles";
import { Grid, Typography, TextField, Paper, Button } from "@material-ui/core/";

const useStyles = makeStyles({
  paper: {
    maxWidth: "-webkit-fill-available",
    minWidth: "-webkit-fill-available",
  },
  noMargin: {
    margin: "0px",
  },
});

export default function TestSourcePage(props) {

  // the data from the text fields
  // firstData state variable
  const [firstData, setFirstDataState] = useState(() => {
    return "";
  });

  // firstData onChange handler
  const firstDataChanged = (e) => {
    setFirstDataState(e.target.value);
  };

  // secondData state variable
  const [secondData, setSecondDataState] = useState(() => {
    return "";
  });

  // secondData onChange handler
  const secondDataChanged = (e) => {
    setSecondDataState(e.target.value);
  };

  const classes = useStyles();

  return (
    <Grid container direction="row" justify="space-evenly" alignItems="center">
      <Grid item xs={false} sm={false} md={false} lg={2} />
      <Grid
        item
        container
        xs={12}
        sm={12}
        md={12}
        lg={8}
        spacing={5}
        direction="column"
        justify="center"
        alignItems="center"
        style={{ paddingTop: "6vh" }}
        classes={{ "spacing-xs-5": classes.noMargin }}
      >
        <Paper elevation={5} style={{ padding: " 5vh 10vw" }}>
          <Grid item>
            <Typography>Test Source Page</Typography>
          </Grid>
          <Grid container spacing={3} classes={{ "spacing-xs-3": classes.noMargin }}>
            <Grid item xs={12} sm={6}>
              <TextField required id="First Data" name="firstData" label="First Data" value={firstData} onChange={firstDataChanged} fullWidth />
            </Grid>
            <Grid item xs={12} sm={6}>
              <TextField required id="Second Data" name="secondData" label="Second Data" value={secondData} onChange={secondDataChanged} fullWidth />
            </Grid>
            <Grid item xs={12}>
              <Link to={{ pathname: "/test-target-page", state: { firstData: firstData, secondData: secondData } }}>
                <Button style={{ width: "20vw" }}>
                  Send Data To Target Page
                </Button>
              </Link>
            </Grid>
          </Grid>
        </Paper>
      </Grid>
      <Grid item xs={false} sm={false} md={false} lg={2} />
    </Grid>
  );
}