import React, { useState, useEffect, useRef } from "react";
import { makeStyles } from "@material-ui/core/styles";
import { Link, Redirect } from "react-router-dom";
import { Button, Container, Paper, Typography, TextField, Grid } from "@material-ui/core";
import Axios from "axios";
import { GoogleLogin } from "react-google-login";
import crypto from 'crypto';

//CLIENT ID FOR GOOGLE SIGN IN
const CLIENT_ID = "609210955285-eokdrnl1p2e59mgi81ol5o45cratn3j5.apps.googleusercontent.com";

const useStyles = makeStyles({
    style1: {},
    style2: {},
});

export default function SignInPage(props) {
    // Destructure Props
    const { functModifyAppState } = props;

    // This functions hadels key presses
    const useKey = (key, cb) => {
        const callBackRef = useRef(cb);
        useEffect(() => {
            callBackRef.current = cb;
        });
        useEffect(() => {
            const handle = (event) => {
                if (event.code === key) {
                    callBackRef.current(event);
                }
            };
            document.addEventListener("keypress", handle);
            return () => document.removeEventListener("keypress", handle);
        }, [key]);
    };
    // Callback Function
    const handleEnter = () => {
        handleSignIn();
    };
    // Handle enter
    useKey("Enter", handleEnter);

    // these block are a series of hooks made to get the data from the text fields
    // the first block is the hook
    const [userName, setUserName] = useState(() => {
        return "";
    });
    // the next block is the onChange handler for the text field
    const userNameChange = (e) => {
        setInError(false);
        setAttempted(false);
        setUserName(e.target.value);
    };

    const [pass, setPass] = useState(() => {
        return "";
    });
    const passChange = (e) => {
        setInError(false);
        setAttempted(false);
        setPass(e.target.value);
    };

    // State to know if entries are in error
    const [inError, setInError] = useState(() => {
        return false;
    });
    // Did the user try to sign in?
    const [attemptedSignIn, setAttempted] = useState(() => {
        return false;
    });

    // Redirect ready?
    const [isPageReadyToBeRedirected, setRedirectState] = useState(() => {
        return false;
    });
    const changeRedirectState = () => {
        setRedirectState(true);
    };
    // Redirect Location
    const [linkToRedirectTo, setLink] = useState(() => {
        return null;
    });
    const changeLink = (lin) => {
        setLink(lin);
    };

    const [isConfirmed, setConfirmed] = useState(() => {
        return true;
    });

    const changeConfirm = () => {
        setConfirmed(false);
    };

    //function for google sign in
    const googleLogin = (res) => {
        console.log("current user: ", res.profileObj);
        //console.log(res.profileObj.email);
        Axios({
            method: "GET",
            url: "http://localhost:5000/handleGoogleSignin",
            headers: {
                "Content-Type": "application/json",
            },
            params: {
                email: res.profileObj.email,
                first: res.profileObj.givenName,
                last: res.profileObj.familyName,
            },
        })
            .then((res) => {
                if (res.data.message === "User not found") {
                    // User is not found so do nothing
                    console.log("User Not Found");
                }
                //no need to do anything user already exists, just sign them in
                else if (res.data.message === "User with no contacts or students") {
                    console.log(res.data.message);
                    let acctD = res.data.userData[0];
                    acctD.accountType = "Customer";
                    functModifyAppState({
                        isSignedIn: true,
                        accountData: acctD,
                    });
                    changeRedirectState();
                    changeLink("/account");
                } else if (res.data.message === "User with contacts") {
                    console.log(res.data.message);
                    console.log(res.data);
                    let acctD = res.data.userData[0];
                    acctD.accountType = "Customer";
                    functModifyAppState({
                        isSignedIn: true,
                        accountData: acctD,
                        contacts: res.data.contactsData,
                    });
                    changeRedirectState();
                    changeLink("/account");
                } else if (res.data.message === "User with Students") {
                    console.log(res.data.message);
                    let acctD = res.data.userData[0];
                    acctD.accountType = "Customer";
                    functModifyAppState({ isSignedIn: true, accountData: acctD, students: res.data.studentData });
                    changeRedirectState();
                    changeLink("/account");
                } else if (res.data.message === "User with students and contacts") {
                    console.log(res.data.message);
                    let acctD = res.data.userData[0];
                    acctD.accountType = "Customer";
                    functModifyAppState({
                        isSignedIn: true,
                        accountData: acctD,
                        students: res.data.studentData,
                        contacts: res.data.contactData,
                    });
                    changeRedirectState();
                    changeLink("/account");
                } else if (res.data.message === "IS ADMIN") {
                    console.log(res.data.message);
                    let accountT = { accountType: "Employee", fname: "Admin" };
                    functModifyAppState({
                        isSignedIn: true,
                        accountData: accountT,
                        users: res.data.users,
                        students: res.data.students,
                        contacts: res.data.contacts,
                        employees: res.data.employees,
                        teachers: res.data.teachers,
                    });
                    changeRedirectState();
                    changeLink("/employee-dashboard");
                } else if (res.data.message === "IS EMP") {
                    console.log(res.data.message);
                    let accountT = { accountType: "Employee", fname: "Emloyee" };
                    functModifyAppState({
                        isSignedIn: true,
                        accountData: accountT,
                        users: res.data.users,
                        students: res.data.students,
                        contacts: res.data.contacts,
                        employees: res.data.employees,
                        teachers: res.data.teachers,
                    });
                    changeRedirectState();
                    changeLink("/employee-dashboard");
                }
                console.log("Signed in with google account");

                // REPLACE THIS WITH REAL DATA FROM THE DATABASE
                // SIMPLY HARDCODING THIS FOR YOU SO THE STATE FULLY CHANGES

                //console.log("FAKE GOOGLE USER DATA FOR - SEE SIGNINPAGE.JS");
                // set that the page is ready to be redirected
            })
            .catch((error) => {
                console.log(error.data);
            });
    };

    const handleGoogleLoginFailure = () => {
        console.log("Failed to log into your google account");
    };

    // Sign in Button OR Enter Key pressed
    const handleSignIn = () => {
        console.log("Standard Sign In Button Pressed");
        setAttempted(true);
        if (userName !== "" && pass !== "") {
            attemptSignIn(userName, pass);
        } else {
            setInError(true);
        }
    };

    const sendEmailConfirmation = () => {
        //console.log("Re-sending confirmation email");
        var token = crypto.randomBytes(8).toString("hex");

        Axios({
            method: "POST",
            url: "http://localhost:5000/sendConfirm",
            headers: {
                "Content-Type": "application/json",
            },
            data: {
                user: userName,
                token: token
            },
        }) .then((res) => {
            if(res.data.message === "Confirm your email sent"){
                console.log("Re-sending confirmation email");
            }
            else {
                console.log("Something went horribly wrong");
            }
        });
    };

    // Sign In Function
    const attemptSignIn = (username, password) => {
        Axios({
            method: "GET",
            url: "http://localhost:5000/login",
            headers: {
                "Content-Type": "application/json",
            },
            params: {
                user: username,
                password: password,
            },
        })
            .then((res) => {
                console.log(res.data.message);
                if (res.data.message === "user does not exist") {
                    setInError(true);
                    console.log(res.data.message);
                } else if (res.data.message === "incorrect password") {
                    setInError(true);
                    console.log(res.data.message);
                } else if (res.data.message === "Email not confirmed") {
                    console.log(res.data.message);
                    changeConfirm();
                } else if (res.data.message === "no students under user") {
                    console.log(res.data.message);
                    let acctD = res.data.userData[0];
                    acctD.accountType = "Customer";
                    functModifyAppState({
                        isSignedIn: true,
                        accountData: acctD,
                        contacts: res.data.contactsData,
                    });
                    changeRedirectState();
                    changeLink("/account");
                } else if (res.data.message === "no students, contacts under user") {
                    console.log(res.data.message);
                    let acctD = res.data.userData[0];
                    acctD.accountType = "Customer";
                    functModifyAppState({
                        isSignedIn: true,
                        accountData: acctD,
                    });
                    changeRedirectState();
                    changeLink("/account");
                } else if (res.data.message === "user login with students, contacts") {
                    console.log(res.data.message);
                    let acctD = res.data.userData[0];
                    acctD.accountType = "Customer";
                    functModifyAppState({
                        isSignedIn: true,
                        accountData: acctD,
                        students: res.data.studentData,
                        contacts: res.data.contactsData,
                    });
                    changeRedirectState();
                    changeLink("/account");
                } else if (res.data.message === "no contacts under user") {
                    console.log(res.data.message);
                    let acctD = res.data.userData[0];
                    acctD.accountType = "Customer";
                    functModifyAppState({ isSignedIn: true, accountData: acctD, students: res.data.studentData });
                    changeRedirectState();
                    changeLink("/account");
                } else {
                    console.log(res.data.message);
                }
            })
            .catch((error) => {
                console.log(error.data);
            });
    };

    // Get helper text for fields if in error
    const getHelper = (val, field) => {
        if (val === "" && attemptedSignIn && inError && (field === "userName" || field === "pass")) {
            return "Required Field";
        } else if (userName !== "" && pass !== "" && attemptedSignIn && inError) {
            return "User Name or Password Incorrect";
        } else {
            return "";
        }
    };
    // Set fields to be in error conditionally
    const getError = (val, field) => {
        if (val === "" && attemptedSignIn && inError && (field === "userName" || field === "pass")) {
            return inError;
        } else if (userName !== "" && pass !== "" && attemptedSignIn && inError) {
            return inError;
        } else {
            return inError;
        }
    };

    // redirect if the page is ready to be redirected
    if (isPageReadyToBeRedirected === true && linkToRedirectTo != null) {
        return <Redirect to={linkToRedirectTo} />;
    }
    // render the page normally
    return (
        <div className="SignInPage">
            <Container style={{ paddingTop: "6vh", paddingBottom: "5vh" }} maxWidth="sm">
                <Paper
                    elevation={10}
                    style={{
                        position: "relative",
                        padding: "4vw",
                    }}
                >
                    <Typography variant="h5">Sign In</Typography>
                    <br></br>
                    <Grid container spacing={3}>
                        <Grid item xs={12}>
                            <TextField
                                id="userNameField"
                                value={userName}
                                onChange={userNameChange}
                                error={getError(userName)}
                                helperText={getHelper(userName, "userName")}
                                label="Email"
                                variant="outlined"
                                fullWidth
                            />
                        </Grid>
                        <Grid item xs={12}>
                            <TextField
                                id="passwordField"
                                value={pass}
                                onChange={passChange}
                                error={getError(pass)}
                                helperText={getHelper(pass, "pass")}
                                label="Password"
                                variant="outlined"
                                type="password"
                                fullWidth
                            />
                        </Grid>

                        <Grid item xs={12}>
                            <Button variant="contained" color="secondary" onClick={handleSignIn} fullWidth>
                                Sign In
                            </Button>
                        </Grid>

                        {!isConfirmed && (
                            <div>
                                <Grid container spacing = {3}>
                                    <Grid item xs = {12}>
                                        <Typography variant="body2" color="textSecondary" component="p">
                                            You haven't confirmed your email address yet, please check your email or click the button below to send another.
                                        </Typography>
                                    </Grid>

                                    <Grid item xs = {12}>
                                        <Button variant="contained" color="secondary" onClick={sendEmailConfirmation} fullWidth>
                                            Send Email
                                        </Button>
                                    </Grid>
                                </Grid>
                            </div>
                        )}

                        <Grid item xs={12}>
                            <GoogleLogin
                                clientId={CLIENT_ID}
                                buttonText="Sign In"
                                onSuccess={googleLogin}
                                onFailure={handleGoogleLoginFailure}
                                cookiePolicy={"single_host_origin"}
                                responseType="code,token"
                            />
                        </Grid>

                        <Grid container spacing={6} direction="column">
                            <Grid item xs={12}>
                                <Link to="/reset-password">Forgot password?</Link>
                                <Grid item xs={12}>
                                    <Link to="/create-account">Create Account</Link>
                                </Grid>
                            </Grid>
                        </Grid>
                    </Grid>
                </Paper>
            </Container>
        </div>
    );
}
