import React from "react";
import { AgGridReact } from "ag-grid-react";
import { Grid, Paper, Container, Typography, Button, ButtonGroup } from "@material-ui/core";
import "ag-grid-community/dist/styles/ag-grid.css";
import "ag-grid-community/dist/styles/ag-theme-alpine.css";

const columns = [
  {
    headerName: "First",
    field: "fname",
    checkboxSelection: true,
  },
  {
    headerName: "Last",
    field: "lname",
  },
  {
    headerName: "Cell",
    field: "phone",
  },
  {
    headerName: "Other Phone",
    field: "homePhone",
  },
  {
    headerName: "Email",
    field: "email",
  },
  {
    headerName: "Address",
    field: "street",
  },
  {
    headerName: "City",
    field: "city",
  },
  {
    headerName: "State",
    field: "state",
  },
  {
    headerName: "Zip",
    field: "zip",
  },
];

const defaultColDef = {
  sortable: true,
  editable: true,
  filter: true,
  floatingFilter: true,
};

export default function UsersTable(props) {
  const { users } = props;
  let gridApi;
  const onGridReady = (params) => {
    gridApi = params.api;
  };
  const onExportClick = () => {
    gridApi.exportDataAsCsv();
  };
  return (
    <Container style={{ paddingTop: "6vh", paddingBottom: "5vh" }} maxWidth="lg">
      <Grid container direction="row" justify="space-evenly" alignItems="center">
        <Paper
          elevation={10}
          style={{
            position: "relative",
            alignContent: "center",
            height: "100%",
            width: "100%",
          }}
        >
          <div className="ag-theme-alpine" style={{ height: "50vh", width: "100%", textAlign: "left" }}>
            <AgGridReact rowData={users} columnDefs={columns} defaultColDef={defaultColDef} onGridReady={onGridReady} />
          </div>
          <div style={{ padding: "2vh" }}>
            <Button variant="contained" color="secondary" onClick={() => onExportClick()}>
              Export
            </Button>
          </div>
        </Paper>
      </Grid>
    </Container>
  );
}
