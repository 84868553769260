import React, { useState } from "react";
import { Link } from "react-router-dom";
import { AgGridReact } from "ag-grid-react";
import { Container, Button, Grid, Paper, Tooltip, IconButton } from "@material-ui/core";
import PersonAddSharpIcon from "@material-ui/icons/PersonAddSharp";
import "ag-grid-community/dist/styles/ag-grid.css";
import "ag-grid-community/dist/styles/ag-theme-alpine.css";
import { GridOptionsWrapper } from "ag-grid-community";

const columns = [
  {
    headerName: "First",
    field: "fname",
  },
  {
    headerName: "Last",
    field: "lname",
  },
  {
    headerName: "Phone",
    field: "phone",
  },
  {
    headerName: "Email",
    field: "email",
  },
  {
    headerName: "Address",
    field: "street",
  },
  {
    headerName: "City",
    field: "city",
  },
  {
    headerName: "State",
    field: "state",
  },
  {
    headerName: "Zip",
    field: "zip",
  },
  {
    headerName: "Birthday",
    field: "birthdate",
    filter: "agDateColumnFilter",
  },
  {
    headerName: "Hire Date",
    field: "hiredate",
    filter: "agDateColumnFilter",
  },
  {
    headerName: "Exit Date",
    field: "exitdate",
    filter: "agDateColumnFilter",
  },
];

const defaultColDef = {
  sortable: true,
  editable: true,
  filter: true,
  floatingFilter: true,
};

export default function TeachersTable(props) {
  const { teachers, functModifyAppState } = props;
  teachers.map((teacher) => {
    console.log(typeof teacher.birthdate);
    teacher.birthdate = teacher.birthdate.toString().substring(0, 10);
    if (teacher.hiredate !== null) {
      teacher.hiredate = teacher.hiredate.toString().substring(0, 10);
    }
  });
  let gridApi;

  // State to know if entries are in error
  // const [isClickable, setIsClickable] = useState(() => {
  //   return true;
  // });

  // const [isSelected, setIsSelected] = useState(() => {
  //   return false;
  // });

  const getSelectedRow = () => {
    teachers.map((teacher) => (teacher.marked = false));
    console.log(teachers);
    let selectedNodes = gridApi.getSelectedNodes();
    let selectedData = selectedNodes.map((node) => node.data);
    selectedData[0].marked = true;
    functModifyAppState({ teachers: teachers });
  };

  const onGridReady = (params) => {
    gridApi = params.api;
  };

  const onExportClick = () => {
    gridApi.exportDataAsCsv();
  };

  return (
    <Container style={{ paddingTop: "6vh", paddingBottom: "5vh" }} maxWidth="lg">
      <Grid container direction="row" justify="space-evenly" alignItems="center">
        <Grid item style={{ width: "100%", paddingBottom: "0px" }}>
          <div className="Add Teacher Button" style={{ float: "right" }}>
            <Tooltip title="Add Teacher" arrow>
              <Link to="/add-teacher">
                <IconButton aria-label="PersonAddSharp">
                  <PersonAddSharpIcon />
                </IconButton>
              </Link>
            </Tooltip>
          </div>
        </Grid>
        <Paper
          elevation={10}
          style={{
            position: "relative",
            alignContent: "center",
            height: "100%",
            width: "100%",
          }}
        >
          <div className="ag-theme-alpine" style={{ height: "50vh", width: "100%", textAlign: "left" }}>
            <AgGridReact
              rowData={teachers}
              columnDefs={columns}
              defaultColDef={defaultColDef}
              onGridReady={onGridReady}
              rowSelection="single"
              onRowSelected={getSelectedRow}
            />
          </div>
          <div style={{ padding: "2vh", justifyContent: "space-evenly" }}>
            <Grid item style={{ justifyContent: "space-evenly" }}>
              <Button variant="contained" color="secondary" onClick={() => onExportClick()}>
                Export
              </Button>
              <Link style={{ textDecoration: "none" }} to="/edit-teacher">
                <Button variant="contained" color="secondary">
                  Edit
                </Button>
              </Link>
            </Grid>
          </div>
        </Paper>
      </Grid>
    </Container>
  );
}
