import React, { useState } from "react";
import { Redirect, useLocation } from 'react-router-dom';
import { makeStyles } from "@material-ui/core/styles";
import { Grid, Typography, TextField, Paper } from "@material-ui/core/";

const useStyles = makeStyles({
  paper: {
    maxWidth: "-webkit-fill-available",
    minWidth: "-webkit-fill-available",
  },
  noMargin: {
    margin: "0px",
  },
});

export default function TestTargetPage(props) {

  // location for passed in props
  const location = useLocation();

  // function to check if location.state is undefined or not
  const checkIfLocationStateIsUndefined = () => {
    if(location.state === undefined){
      console.log("undefined");
      return true;
    }
    else{
      console.log("defined");
      return false;
    }
  }

  // the data from the text fields
  // firstData state variable
  const [firstData, setFirstDataState] = useState(() => {
    // check if we got here through the proper means
    if(checkIfLocationStateIsUndefined() === false){
      return location.state.firstData;
    }
    else{
      // empty string to make it not crash - page should just redirect tho
      return "";
    }
  });

  // secondData state variable
  const [secondData, setSecondDataState] = useState(() => {
    // check if we got here through the proper means
    if(checkIfLocationStateIsUndefined() === false){
      return location.state.secondData;
    }
    else{
      // empty string to make it not crash - page should just redirect tho
      return "";
    }
  });

  const classes = useStyles();


  // redirect to the SOURCE OF THE DATA THAT SHOULD BE PROVIDED TO THIS PAGE in the event the user gets to this page without clicking the <Link /> that includes the state data
  if (checkIfLocationStateIsUndefined() === true) {
    console.log("should be redirecting");
    return <Redirect to="/test-source-page" />;
  }
  // render the page normally
  else {
    console.log("not redirecting");
    return (
      <Grid container direction="row" justify="space-evenly" alignItems="center">
        <Grid item xs={false} sm={false} md={false} lg={2} />
        <Grid
          item
          container
          xs={12}
          sm={12}
          md={12}
          lg={8}
          spacing={5}
          direction="column"
          justify="center"
          alignItems="center"
          style={{ paddingTop: "6vh" }}
          classes={{ "spacing-xs-5": classes.noMargin }}
        >
          <Paper elevation={5} style={{ padding: " 5vh 10vw" }}>
            <Grid item>
              <Typography>Test Target Page</Typography>
            </Grid>
            <Grid container spacing={3} classes={{ "spacing-xs-3": classes.noMargin }}>
              <Grid item xs={12} sm={6}>
                <TextField required id="First Data" name="firstData" label="First Data" value={firstData} fullWidth />
              </Grid>
              <Grid item xs={12} sm={6}>
                <TextField required id="Second Data" name="secondData" label="Second Data" value={secondData} fullWidth />
              </Grid>
            </Grid>
          </Paper>
        </Grid>
        <Grid item xs={false} sm={false} md={false} lg={2} />
      </Grid>
    );
  }
}