import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import { Grid } from "@material-ui/core";
import Student from "./Student";

const useStyles = makeStyles({
  noMargin: {
    margin: "0",
  },
  grid: {
    maxWidth: "-webkit-fill-available",
    minWidth: "-webkit-fill-available",
  },
});

export default function Students(props) {
  const { students } = props;
  const classes = useStyles();
  return (
    <Grid container spacing={3} alignItems="flex-start" justify="center" className={classes.grid} classes={{ "spacing-xs-3": classes.noMargin }}>
      {students.map((student) => {
        return (
          <Grid key={student.fname} item className="Student">
            <Student student={student} />
          </Grid>
        );
      })}
    </Grid>
  );
}
