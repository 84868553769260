import React, { Component } from "react";
import { Link, Redirect } from 'react-router-dom';
import { Button, Typography, Paper, TextField, Grid, Container, makeStyles } from "@material-ui/core";
import Axios from "axios";

export default class ConfirmEmailPage extends Component {

  constructor() {
    super();

    this.state = {
      isPageReadyToBeRedirected: false,
      linkToRedirectTo: null,

      error: true,
    };
    // bind functions here
    this.goToSignIn = this.goToSignIn.bind(this);
    this.goHome = this.goHome.bind(this);
  }

  async componentDidMount(){

    console.log(window.location.pathname);
    var path = window.location.pathname;
    var token = path.split("/");
    console.log(token[2]);


    await Axios({
      method: "GET",
      url: "http://localhost:5000/confirmEmail",
      headers: {
        "Content-Type": "application/json",
      },
      params: {
        token: token[2]
      },
    }).then((res) => {
      if(res.data.message === 'Token not found'){
        console.log(res.data.message);
        console.log('Incorrect Token');
      }
      else if(res.data.message === 'success'){
        console.log('email confirmed')
        this.setState({ 
          error: false
        });
      }
    });
  }

  goToSignIn(){
    this.setState({
      isPageReadyToBeRedirected: true,
      linkToRedirectTo: "/sign-in",
    });
  }

  goHome(){
    this.setState({
      isPageReadyToBeRedirected: true,
      linkToRedirectTo: "/Welcome",
    });
  }

  render() {
  
    const{ error } = this.state;

    if(this.state.isPageReadyToBeRedirected === true && this.state.linkToRedirectTo != null){
      return <Redirect to={this.state.linkToRedirectTo} />;
    }
    // render the page normally
    else{
      if(!error){
        return (
          <div className="Confirm Email Page">
            <Container style={{ paddingTop: "6vh", paddingBottom: "5vh" }} maxWidth="sm">
              <Paper
                elevation={10}
                style={{
                  position: "relative",
                  padding: "4vw",
                }}
              >
                <Typography variant="h5">Email Confirmed!</Typography>
                <br></br>
                <Grid container spacing={3}>
                  <Typography variant="body2" color="textSecondary" component="p">
                    Thank you for confirming your email address, please sign in to complete your account.
                  </Typography>
      
                  <Grid item xs={12}>
                    <Button variant="contained" color="secondary" onClick={this.goToSignIn}>
                      Sign in
                    </Button>
                  </Grid>
      
                </Grid>
              </Paper>
            </Container>
          </div>
        );
      } else {
        return (
          <div className="Confirm Email Page">
            <Container style={{ paddingTop: "6vh", paddingBottom: "5vh" }} maxWidth="sm">
              <Paper
                elevation={10}
                style={{
                  position: "relative",
                  padding: "4vw",
                }}
              >
                <Typography variant="h5">Uh Oh!</Typography>
                <br></br>
                <Grid container spacing={3}>
                  <Typography variant="body2" color="textSecondary" component="p">
                    There was an error, please try again or create a new account
                  </Typography>
      
                  <Grid item xs={12}>
                    <Button variant="contained" color="secondary" onClick={this.goHome}>
                      Go Home
                    </Button>
                  </Grid>
      
                </Grid>
              </Paper>
            </Container>
          </div>
        );
      }
    }
  }
}
