import React, { Component } from "react";
import { Link } from 'react-router-dom';
import { makeStyles} from "@material-ui/styles"
import { Table, TableBody, TableCell, TableContainer, TableHead, TableRow } from "@material-ui/core";
import { Grid, Typography, Paper, Tooltip, IconButton } from "@material-ui/core";
import Card from '@material-ui/core/Card';
import CardActionArea from '@material-ui/core/CardActionArea';
import CardActions from '@material-ui/core/CardActions';
import CardContent from '@material-ui/core/CardContent';
import CardMedia from '@material-ui/core/CardMedia';
import Button from '@material-ui/core/Button';
import Axios from "axios";
import Slide from '@material-ui/core/Slide';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
//Dialog box should be added to First Teacher. Need to add to the rest/ Actually test in Teacher Pages 

const useStyles = makeStyles({
    gridContainer: {
        paddingLeft: "150px",
        paddingRight: "20px",
        paddingTop: "20px",
    },
    root: {
      maxWidth: 345,
    },
    media: {
      height: 140,
    },
  });
//Dialog pop up transition
  const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />;
  });

export default function TeachersPage(props)  {
    var myTeachers = [];
    //Array to hold all Teachers
    //The idea is that the axios call will populate this array
    //Then we can call teacher1.jpg to populate the different cards

    const { functModifyAppState } = props;

    //these three needed for dialog box. 
    const [open, setOpen] = React.useState(false);

    const handleClickOpen = () => {
      setOpen(true);
    };
  
    const handleClose = () => {
      setOpen(false);
    };


    Axios({
        method: "GET",
        url: "http://localhost:5000/teachers",
        headers: {
          "Content-Type": "application/json",
        },
      }).then((res) => {
          //I believe it would be something like this to grab the incoming Teachers Array 
        functModifyAppState({ teachers: myTeachers });
      })
    //console.log(this.props.userName);
    //Attempting to see if I can accesss props properly
    const classes= useStyles();
       // return <h5>TEACHER PAGE</h5>;
       return(
       
        <Grid container spacing={1} className={classes.gridContainer} justify="center">
            <Grid item xs={12} justify="center">
          <Typography variant="h3" gutterBottom>Meet the Teachers</Typography>
        </Grid>
        <Grid item md={3}>
            <Card className={classes.root}>
            <CardActionArea>
            <CardMedia
                className={classes.media}
                image="/static/images/cards/contemplative-reptile.jpg"
                title="Contemplative Reptile"
            />
            <CardContent>
                <Typography gutterBottom variant="h5" component="h2">
                Teach 1
                </Typography>
                <Typography variant="body2" color="textSecondary" component="p">
                Lizards are a widespread group of squamate reptiles, with over 6,000 species, ranging
                across all continents except Antarctica
                </Typography>
            </CardContent>
            </CardActionArea>
            <CardActions>
            <Button size="small" color="primary" onClick={handleClickOpen}>
                Learn More
            </Button>
            <Dialog
                open={open}
                TransitionComponent={Transition}
                keepMounted
                onClose={handleClose}
                aria-labelledby="alert-dialog-slide-title"
                aria-describedby="alert-dialog-slide-description"
            >
                <DialogTitle id="alert-dialog-slide-title">{"What I teach:"}</DialogTitle>
                <DialogContent>
                <DialogContentText id="alert-dialog-slide-description">
                    Trumpet. Tap Dancing. Breakdancing. 
                </DialogContentText>
                </DialogContent>
                <DialogActions>
                <Button onClick={handleClose} color="primary">
                    Close
                </Button>
                </DialogActions>
            </Dialog>
            </CardActions>
        </Card>
        </Grid>

        <Grid item md={3}>
            <Card className={classes.root}>
            <CardActionArea>
            <CardMedia
                className={classes.media}
                image="/static/images/cards/contemplative-reptile.jpg"
                title="Contemplative Reptile"
            />
            <CardContent>
                <Typography gutterBottom variant="h5" component="h2">
                Teach 2
                </Typography>
                <Typography variant="body2" color="textSecondary" component="p">
                Lizards are a widespread group of squamate reptiles, with over 6,000 species, ranging
                across all continents except Antarctica
                </Typography>
            </CardContent>
            </CardActionArea>
            <CardActions>
            <Button size="small" color="primary">
                Sign up with this Teacher 
            </Button>
            </CardActions>
        </Card>
        </Grid>
        <Grid item md={3}>
            <Card className={classes.root}>
            <CardActionArea>
            <CardMedia
                className={classes.media}
                image="/static/images/cards/contemplative-reptile.jpg"
                title="Contemplative Reptile"
            />
            <CardContent>
                <Typography gutterBottom variant="h5" component="h2">
                Teach 3
                </Typography>
                <Typography variant="body2" color="textSecondary" component="p">
                Lizards are a widespread group of squamate reptiles, with over 6,000 species, ranging
                across all continents except Antarctica
                </Typography>
            </CardContent>
            </CardActionArea>
            <CardActions>
            <Button size="small" color="primary">
                Sign up with this Teacher 
            </Button>
            </CardActions>
        </Card>
        </Grid>
        <Grid item md={3}>
            <Card className={classes.root}>
            <CardActionArea>
            <CardMedia
                className={classes.media}
                image="/static/images/cards/contemplative-reptile.jpg"
                title="Contemplative Reptile"
            />
            <CardContent>
                <Typography gutterBottom variant="h5" component="h2">
                Teach 4
                </Typography>
                <Typography variant="body2" color="textSecondary" component="p">
                Lizards are a widespread group of squamate reptiles, with over 6,000 species, ranging
                across all continents except Antarctica
                </Typography>
            </CardContent>
            </CardActionArea>
            <CardActions>
            <Button size="small" color="primary">
                Sign up with this Teacher 
            </Button>
            </CardActions>
        </Card>
        </Grid>
        <Grid item md={3}>
            <Card className={classes.root}>
            <CardActionArea>
            <CardMedia
                className={classes.media}
                image="/static/images/cards/contemplative-reptile.jpg"
                title="Contemplative Reptile"
            />
            <CardContent>
                <Typography gutterBottom variant="h5" component="h2">
                Teach 5
                </Typography>
                <Typography variant="body2" color="textSecondary" component="p">
                Lizards are a widespread group of squamate reptiles, with over 6,000 species, ranging
                across all continents except Antarctica
                </Typography>
            </CardContent>
            </CardActionArea>
            <CardActions>
            <Button size="small" color="primary">
                Sign up with this Teacher 
            </Button>
            </CardActions>
        </Card>
        </Grid>
        <Grid item md={3}>
            <Card className={classes.root}>
            <CardActionArea>
            <CardMedia
                className={classes.media}
                image="/static/images/cards/contemplative-reptile.jpg"
                title="Contemplative Reptile"
            />
            <CardContent>
                <Typography gutterBottom variant="h5" component="h2">
                Teach 6
                </Typography>
                <Typography variant="body2" color="textSecondary" component="p">
                Lizards are a widespread group of squamate reptiles, with over 6,000 species, ranging
                across all continents except Antarctica
                </Typography>
            </CardContent>
            </CardActionArea>
            <CardActions>
            <Button size="small" color="primary">
                Sign up with this Teacher 
            </Button>
            </CardActions>
        </Card>
        </Grid>
        <Grid item md={3}>
            <Card className={classes.root}>
            <CardActionArea>
            <CardMedia
                className={classes.media}
                image="/static/images/cards/contemplative-reptile.jpg"
                title="Contemplative Reptile"
            />
            <CardContent>
                <Typography gutterBottom variant="h5" component="h2">
                Teach 7
                </Typography>
                <Typography variant="body2" color="textSecondary" component="p">
                Lizards are a widespread group of squamate reptiles, with over 6,000 species, ranging
                across all continents except Antarctica
                </Typography>
            </CardContent>
            </CardActionArea>
            <CardActions>
            <Button size="small" color="primary">
                Sign up with this Teacher 
            </Button>
            </CardActions>
        </Card>
        </Grid>
        <Grid item md={3}>
            <Card className={classes.root}>
            <CardActionArea>
            <CardMedia
                className={classes.media}
                image="/static/images/cards/contemplative-reptile.jpg"
                title="Contemplative Reptile"
            />
            <CardContent>
                <Typography gutterBottom variant="h5" component="h2">
                Teach 8
                </Typography>
                <Typography variant="body2" color="textSecondary" component="p">
                Lizards are a widespread group of squamate reptiles, with over 6,000 species, ranging
                across all continents except Antarctica
                </Typography>
            </CardContent>
            </CardActionArea>
            <CardActions>
            <Button size="small" color="primary">
                Sign up with this Teacher 
            </Button>
            </CardActions>
        </Card>
        </Grid>
        <Grid item md={3}>
            <Card className={classes.root}>
            <CardActionArea>
            <CardMedia
                className={classes.media}
                image="/static/images/cards/contemplative-reptile.jpg"
                title="Contemplative Reptile"
            />
            <CardContent>
                <Typography gutterBottom variant="h5" component="h2">
                Teach 9
                </Typography>
                <Typography variant="body2" color="textSecondary" component="p">
                Lizards are a widespread group of squamate reptiles, with over 6,000 species, ranging
                across all continents except Antarctica
                </Typography>
            </CardContent>
            </CardActionArea>
            <CardActions>
            <Button size="small" color="primary">
                Sign up with this Teacher 
            </Button>
            </CardActions>
        </Card>
        </Grid>
        <Grid item md={3}>
            <Card className={classes.root}>
            <CardActionArea>
            <CardMedia
                className={classes.media}
                image="/static/images/cards/contemplative-reptile.jpg"
                title="Contemplative Reptile"
            />
            <CardContent>
                <Typography gutterBottom variant="h5" component="h2">
                Teach 10
                </Typography>
                <Typography variant="body2" color="textSecondary" component="p">
                Lizards are a widespread group of squamate reptiles, with over 6,000 species, ranging
                across all continents except Antarctica
                </Typography>
            </CardContent>
            </CardActionArea>
            <CardActions>
            <Button size="small" color="primary">
                Sign up with this Teacher 
            </Button>
            </CardActions>
        </Card>
        </Grid>
        <Grid item md={3}>
            <Card className={classes.root}>
            <CardActionArea>
            <CardMedia
                className={classes.media}
                image="/static/images/cards/contemplative-reptile.jpg"
                title="Contemplative Reptile"
            />
            <CardContent>
                <Typography gutterBottom variant="h5" component="h2">
                Teach 11
                </Typography>
                <Typography variant="body2" color="textSecondary" component="p">
                Lizards are a widespread group of squamate reptiles, with over 6,000 species, ranging
                across all continents except Antarctica
                </Typography>
            </CardContent>
            </CardActionArea>
            <CardActions>
            <Button size="small" color="primary">
                Sign up with this Teacher 
            </Button>
            </CardActions>
        </Card>
        </Grid>
        <Grid item md={3}>
            <Card className={classes.root}>
            <CardActionArea>
            <CardMedia
                className={classes.media}
                image="/static/images/cards/contemplative-reptile.jpg"
                title="Contemplative Reptile"
            />
            <CardContent>
                <Typography gutterBottom variant="h5" component="h2">
                Teach 12
                </Typography>
                <Typography variant="body2" color="textSecondary" component="p">
                Lizards are a widespread group of squamate reptiles, with over 6,000 species, ranging
                across all continents except Antarctica
                </Typography>
            </CardContent>
            </CardActionArea>
            <CardActions>
            <Button size="small" color="primary">
                Sign up with this Teacher 
            </Button>
            </CardActions>
        </Card>
        </Grid>
      </Grid>
      
      
      
    );
    
        
    
}
