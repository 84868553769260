import React, { useState } from "react";
import { Button, Typography, makeStyles, Paper, Container, Grid, TextField } from "@material-ui/core";
import Axios from "axios";
import crypto from 'crypto';

const accountCreationStyles = makeStyles((theme) => ({
  root: {
    height: 100,
    width: 50,
  },
}));

export default function ResetPasswordPage(){
  const[email, setEmail] = useState(() => {
    return null;
  });
  const changeEmail = (email) => {
    setEmail(email);
  };

  const[showError, setError] = useState(() => {
    return false;
  });
  const changeError = (bool) => {
    setError(bool);
  };

  const[messageFromServer, setMessage] = useState(() => {
    return null;
  });
  const changeMessage = (message) => {
    setMessage(message);
  };

  const emailChanged = (e) => {
    setEmail(e.target.value);
  }

  const attemptPasswordReset = () => {
    console.log("Email: " + email);

    if (email === "") {
      changeMessage("");
    } else {
      var token = crypto.randomBytes(8).toString("hex");

      Axios({
        method: "GET",
        url: "http://localhost:5000/findEmail",
        headers: {
          "Content-Type": "application/json",
        },
        params: {
          email: email,
          token: token,
        },
      })
        .then((res) => {
          console.log(res.data.message);
          if (res.data.message === "email not in db") {
            console.log("email not in db");
            changeError(true);
            changeMessage("");
          } else if (res.data.message === "recovery email sent!") {
            console.log("recovery sent hopefully");
            changeError(false);
            changeMessage("recovery email sent");
          }
        })
        .catch((error) => {
          console.log(error.data);
        });
    }
  };

  return (
    <div className={accountCreationStyles.root}>
      <Container style={{ paddingTop: "6vh", paddingBottom: "5vh" }} maxWidth="sm">
        <Paper
          elevation={10}
          style={{
            position: "relative",
            padding: "4vw",
          }}
        >
          <Grid container spacing={3}>
            <Grid item xs={12}>
              <Typography variant="h5"> Forgot Password? </Typography>
            </Grid>
            <Grid item xs={12}>
              <Typography variant="h5"> Enter the email you registered with</Typography>
            </Grid>
            <Grid itme xs={12}>
              <TextField
                    variant="outlined"
                    id="emailField"
                    name="emailAddress"
                    label="Email"
                    fullWidth
                    autoComplete="email"
                    required
                    onChange={emailChanged}
                    value={email}
                />
            </Grid>
            <Grid item xs={12}>
              <Button variant="contained" color="secondary" onClick={attemptPasswordReset}>
                Send Email
              </Button>
            </Grid>
            {showError && (
              <div>
                <Grid container spacing = {3}>
                  <Grid item xs = {12}>
                    <p>That email isn't recognized. Try again or register a new account.</p>
                  </Grid>
                </Grid>
              </div>
            )}
            {messageFromServer === "recovery email sent" && (
              <div>
                  <Grid container spacing = {3}>
                    <Grid item xs = {12}>
                      <p>Recovery Email Sent!</p>
                    </Grid>
                  </Grid>
              </div>
            )}
          </Grid>
        </Paper>
      </Container>
    </div>
  );
}
