import React from "react";
import { AppBar, Toolbar } from "@material-ui/core";

import HeaderButtons from "./HeaderButtons.js";
import HeaderAccountRegion from "./HeaderAccountRegion.js";

export default function Header(props) {
    return (
        <div className="Main Header" style={{ position: "relative" }}>
            <AppBar color="default" position="static">
                <Toolbar>
                    <a className="TitleImg" href="http://mclaughlinstudios.com/" target="_blank" rel="noopener noreferrer">
                        <img
                            href="http://mclaughlinstudios.com/"
                            id="siteNameImage"
                            src="./images/mclaughlin_studios_icon.png"
                            alt="site name"
                            style={{ verticalAlign: "middle", width: "250px", height: "96px" }}
                        />
                    </a>
                    <HeaderButtons isSignedIn={props.isSignedIn} accountType={props.accountType} />
                    <HeaderAccountRegion
                        isSignedIn={props.isSignedIn}
                        accountType={props.accountType}
                        userName={props.userName}
                        functModifyAppState={props.functModifyAppState}
                    />
                </Toolbar>
            </AppBar>
        </div>
    );
}
