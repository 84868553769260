import React from "react";
import { Link, Redirect } from "react-router-dom";
import { makeStyles } from "@material-ui/core/styles";
import { Table, TableBody, TableCell, TableContainer, TableHead, TableRow } from "@material-ui/core";
import { Grid, Typography, Paper, Tooltip, IconButton } from "@material-ui/core";
import PersonAddSharpIcon from "@material-ui/icons/PersonAddSharp";
import EditIcon from "@material-ui/icons/Edit";
import AddCircleOutlineOutlinedIcon from "@material-ui/icons/AddCircleOutlineOutlined";

import Students from "./Students.js";

const useStyles = makeStyles({
    table: {
        width: "100px",
    },
    paper: {
        maxWidth: "-webkit-fill-available",
        minWidth: "-webkit-fill-available",
    },
    noMargin: {
        margin: "0px",
    },
});

export default function AccountPage(props) {
    const { accountData, contacts, students, functModifyAppState } = props;
    const { fname, lname, cellPhone, homePhone, email, street, city, state, zip, firstTimeSignIn } = accountData;
    const classes = useStyles();

    const markContact = (id) => {
        const conts = [
            contacts.map((contact) => {
                if (id === contact.ID) {
                    contact.marked = true;
                }
                return contact;
            }),
        ];
        functModifyAppState({
            contacts: conts[0],
        });
    };

    // redirect to the First Time Data stuff if it hasn't been completed yet
    if (firstTimeSignIn === 1) {
      return <Redirect to="/complete-account-creation" />;
    }
    // render the page normally
    else {
      return (
          <Grid container direction="row" justify="space-evenly" alignItems="center">
              <Grid item xs={false} sm={false} md={false} lg={2} />
              <Grid
                  item
                  container
                  xs={12}
                  sm={12}
                  md={12}
                  lg={8}
                  spacing={5}
                  direction="column"
                  justify="flex-start"
                  alignItems="flex-start"
                  classes={{ "spacing-xs-5": classes.noMargin }}
              >
                  <Grid item>
                      <Typography>My Account</Typography>
                  </Grid>
                  <Paper className={classes.paper} elevation={6}>
                      <Grid item>
                          <TableContainer>
                              <Table className={useStyles.table} aria-label="simple table">
                                  <TableHead>
                                      <TableRow>
                                          <TableCell style={{ fontWeight: "bold" }}>First</TableCell>
                                          <TableCell align="center" style={{ fontWeight: "bold" }}>
                                              Last
                                          </TableCell>
                                          <TableCell align="center" style={{ fontWeight: "bold" }}>
                                              Primary #
                                          </TableCell>
                                          <TableCell align="center" style={{ fontWeight: "bold" }}>
                                              Secondary #
                                          </TableCell>
                                          <TableCell align="center" style={{ fontWeight: "bold" }}>
                                              E-Mail
                                          </TableCell>
                                          <TableCell align="center" style={{ fontWeight: "bold" }}>
                                              Address
                                          </TableCell>
                                          <TableCell align="center" style={{ fontWeight: "bold" }}>
                                              City
                                          </TableCell>
                                          <TableCell align="center" style={{ fontWeight: "bold" }}>
                                              State
                                          </TableCell>
                                          <TableCell align="center" style={{ fontWeight: "bold" }}>
                                              Zip
                                          </TableCell>
                                          <TableCell align="center" style={{ fontWeight: "bold" }}>
                                              Actions
                                          </TableCell>
                                      </TableRow>
                                  </TableHead>
                                  <TableBody>
                                      <TableRow key={fname}>
                                          <TableCell component="th" scope="row">
                                              {fname}
                                          </TableCell>
                                          <TableCell align="center">{lname}</TableCell>
                                          <TableCell align="center">{cellPhone}</TableCell>
                                          <TableCell align="center">{homePhone}</TableCell>
                                          <TableCell align="center">{email}</TableCell>
                                          <TableCell align="center">{street}</TableCell>
                                          <TableCell align="center">{city}</TableCell>
                                          <TableCell align="center">{state}</TableCell>
                                          <TableCell align="center">{zip}</TableCell>
                                          <TableCell align="center">
                                              <Tooltip title="Add Contact" arrow>
                                                  <Link to="/add-an-account-contact">
                                                      <IconButton aria-label="AddCircleOutlineOutlinedIcon">
                                                          <AddCircleOutlineOutlinedIcon />
                                                      </IconButton>
                                                  </Link>
                                              </Tooltip>
                                              <Tooltip title="Edit Account" arrow>
                                                  <Link to="/edit-account">
                                                      <IconButton aria-label="edit">
                                                          <EditIcon />
                                                      </IconButton>
                                                  </Link>
                                              </Tooltip>
                                          </TableCell>
                                      </TableRow>
                                  </TableBody>
                              </Table>
                          </TableContainer>
                      </Grid>
                  </Paper>
                  <Grid item>My Contacts</Grid>
                  <Paper elevation={6} className={classes.paper}>
                      <Grid item>
                          <TableContainer>
                              <Table className={useStyles.table} aria-label="simple table">
                                  <TableHead>
                                      <TableRow>
                                          <TableCell style={{ fontWeight: "bold" }}>First</TableCell>
                                          <TableCell align="center" style={{ fontWeight: "bold" }}>
                                              Last
                                          </TableCell>
                                          <TableCell align="center" style={{ fontWeight: "bold" }}>
                                              Cell
                                          </TableCell>
                                          <TableCell align="center" style={{ fontWeight: "bold" }}>
                                              Home Phone
                                          </TableCell>
                                          <TableCell align="center" style={{ fontWeight: "bold" }}>
                                              E-Mail
                                          </TableCell>
                                          <TableCell align="center" style={{ fontWeight: "bold" }}>
                                              Relation
                                          </TableCell>
                                          <TableCell align="center" style={{ fontWeight: "bold" }}>
                                              Actions
                                          </TableCell>
                                      </TableRow>
                                  </TableHead>
                                  <TableBody>
                                      {contacts.map((row) => (
                                          <TableRow key={row.ID}>
                                              <TableCell component="th" scope="row">
                                                  {row.fname}
                                              </TableCell>
                                              <TableCell align="center">{row.lname}</TableCell>
                                              <TableCell align="center">{row.cellPhone}</TableCell>
                                              <TableCell align="center">{row.homePhone}</TableCell>
                                              <TableCell align="center">{row.email}</TableCell>
                                              <TableCell align="center">{row.relation}</TableCell>
                                              <TableCell align="center">
                                                  <Tooltip title="Edit Contact" arrow>
                                                    <Link to="/edit-contact" onClick={() => markContact(row.ID)}>
                                                      <IconButton aria-label="edit">
                                                          <EditIcon />
                                                      </IconButton>
                                                    </Link>
                                                  </Tooltip>
                                              </TableCell>
                                          </TableRow>
                                      ))}
                                  </TableBody>
                              </Table>
                          </TableContainer>
                      </Grid>
                  </Paper>
                  <Grid item style={{ width: "100%", paddingBottom: "0px" }}>
                      <Typography style={{ float: "left" }}>My Students</Typography>
                      <div className="Add Student Button" style={{ float: "right" }}>
                          <Tooltip title="Add Student" arrow>
                              <Link to="/add-student">
                                  <IconButton aria-label="PersonAddSharp">
                                      <PersonAddSharpIcon />
                                  </IconButton>
                              </Link>
                          </Tooltip>
                      </div>
                  </Grid>
                  <Paper elevation={6} className={classes.paper}>
                      <Students students={students} />
                  </Paper>
              </Grid>
              <Grid item xs={false} sm={false} md={false} lg={2} />
          </Grid>
      );
    }
}
