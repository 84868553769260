import React, { Component } from "react";
import { Link, Redirect} from 'react-router-dom';
import { Button, Typography, Paper, TextField, Grid, Container, makeStyles } from "@material-ui/core";
import Axios from "axios"
//import { response } from "express";



export default class EmailResetPassPage extends Component {

  constructor() {
    super();

    this.state = {
      isPageReadyToBeRedirected: false,
      linkToRedirectTo: null,
      username: '',
      update: false,
      error: true,
    };

    // bind functions here
    this.goHome = this.goHome.bind(this);
    this.forgotPass = this.forgotPass.bind(this);
    this.goLogin = this.goLogin.bind(this);
    this.resetPass = this.resetPass.bind(this);

}

  async componentDidMount(){
   //const { match: { params } } = this.props;

   var path = window.location.pathname;
   var resToken = path.split("/");
   console.log(resToken[2]);
   console.log(this.props.match.params.token);

    //console.log(window.location.pathname);
    //console.log(this.props.match.params.token);

    //console.log(this.props.match.params.token);
    await Axios({
        method: "GET",
        url: "http://localhost:5000/findToken",
        headers: {
            "Content-Type": "application/json",
        },
        params: {
            resetToken: this.props.match.params.token,
        },
        }).then((res) => {
        if(res.data.message === 'Invalid Token'){
            console.log(res.data.message);
                this.setState({
                update: false,
                error: true,
            });
        }
        else if(res.data.message === 'Valid Token'){
            console.log(res.data.message);
            console.log(res.data.userName);
            this.setState({ 
                username: res.data.userName,
                update: false,
                error: false,
            });
            }
        });
  }
  

  resetPass(){
    let passwordInputField = document.getElementById("passwordField");
    let passwordValue = passwordInputField.value;

    let confirmPasswordInputField = document.getElementById("confirmPasswordField");
    let confirmPasswordValue = confirmPasswordInputField.value;

    console.log(this.state.username);
    console.log(passwordValue);

    if(confirmPasswordValue == passwordValue){
      Axios({
        method: "POST",
        url: "http://localhost:5000/updatePasswordViaEmail",
        headers: {
          "Content-Type": "application/json",
        },
        data: {
          email: this.state.username,
          password: passwordValue,
        },
      }).then((res) => {
        if(res.data.message === "Password changed successfully"){
            console.log(res.data.message);
            this.setState({
                updated: true,
                error: false,
            });
        }
        else{
            this.setState({
                updated: false,
                error: true
            });
        }
      });
    }
  }

  goHome(){
    this.setState({ 
        isPageReadyToBeRedirected: true,
        linkToRedirectTo: "/welcome"
    });
  }

  goLogin(){
    this.setState({ 
        isPageReadyToBeRedirected: true,
        linkToRedirectTo: "/sign-in"
    }); 
  }

  forgotPass(){
    this.setState({ 
        isPageReadyToBeRedirected: true,
        linkToRedirectTo: "/reset-password"
    });
  }

  
  render() {
  // redirect if the page is ready to be redirected
    const {error, updated} = this.state;

    if(this.state.isPageReadyToBeRedirected === true && this.state.linkToRedirectTo != null){
      return <Redirect to={this.state.linkToRedirectTo} />;
    }
    // render the page normally
    else{
        if(error){
            return (
                <div>
                <Container style={{ paddingTop: "6vh", paddingBottom: "5vh" }} maxWidth="sm">
                    <Paper
                    elevation={10}
                    style={{
                        position: "relative",
                        padding: "4vw",
                    }}
                    >
                    <Typography variant="h4">Problem resetting password. Please send another reset link</Typography>
                    <br></br>
                    <Grid container spacing={3}>
                        <Grid item xs={12}>
                        <Button variant="contained" color="secondary" onClick={this.goHome}>
                            Go Home
                        </Button>
                        </Grid>
                        <Grid item xs={12}>
                        <Button variant="contained" color="secondary" onClick={this.forgotPass}>
                            Forgot Password
                        </Button>
                        </Grid>
                    </Grid>
                    </Paper>
                </Container>
                </div>
            );
        }
        else {
            return(
                <div>
                    <Container style={{ paddingTop: "6vh", paddingBottom: "5vh" }} maxWidth="sm">
                        <Paper
                            elevation={10}
                            style={{
                                position: "relative",
                                padding: "4vw",
                            }}
                            >
                            <Typography variant="h5">Reset Password</Typography>
                            <br></br>
                            <Grid container spacing={3}>
                                <Grid item xs={12}>
                                    <TextField variant="outlined" id="passwordField" name="password" label="Create Password" fullWidth autoComplete="createPassword" />
                                </Grid>
                                <Grid item xs={12}>
                                    <TextField variant="outlined" id="confirmPasswordField" name="confirmPassword" label="Confirm Password" fullWidth autoComplete="createPassword" />
                                </Grid>
                                <Grid item xs={12}>
                                    <Button variant="contained" color="secondary" onClick={this.resetPass}>
                                        Update Password
                                    </Button>
                                </Grid>
                                {updated && (
                                    <div>
                                        <br></br>
                                        <Typography variant="body2" color="textSecondary" component="p">
                                            Password reset successfully, please try logging in again.
                                        </Typography>
                                        <br></br>
                                        <Button variant="contained" color="secondary" onClick={this.goLogin}>
                                            Login
                                        </Button>
                                    </div>
                                )}
                            </Grid>
                        </Paper>
                    </Container>
                </div>
            )
        }
    }
  }
}
