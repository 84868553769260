import React, { Component } from "react";
import { Link, Redirect } from "react-router-dom";
import { Button } from "@material-ui/core";

export default class BadTargetPage extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isPageReadyToBeRedirected: false,
      linkToRedirectTo: null,
    };
    // bind functions here
    this.resetAppState = this.resetAppState.bind(this);
  }

  // function for resetting the state of the app to default values
  resetAppState() {
    // reset the App state variables
    this.props.functModifyAppState({
      isSignedIn: false,
      accountData: {
        accountType: "None",
      },
      students: [],
      contacts: [],
      tempStudent: {},
    });

    // set the local state variables for being redirected
    this.setState({
      isPageReadyToBeRedirected: true,
      linkToRedirectTo: "/welcome",
    });
  }

  render() {
    // redirect if to the welcome page after resetting the app state
    if (this.state.isPageReadyToBeRedirected === true && this.state.linkToRedirectTo != null) {
      return <Redirect to={this.state.linkToRedirectTo} />;
    }
    // case: not signed in
    else if (this.props.isSignedIn === false) {
      return (
        <div>
          <h1>404 PAGE NOT FOUND</h1>
          <br></br>
          <Link style={{ textDecoration: "none" }} to="/welcome">
            <Button variant="contained" color="secondary">
              Click here to be redirected to the Welcome Page
            </Button>
          </Link>
        </div>
      );
    }
    // case: signed in as Customer
    else if (this.props.isSignedIn === true && this.props.accountType === "Customer") {
      return (
        <div>
          <h1>404 PAGE NOT FOUND</h1>
          <br></br>
          <Link style={{ textDecoration: "none" }} to="/account">
            <Button variant="contained" color="secondary">
              Click here to be redirected to the Account Page
            </Button>
          </Link>
        </div>
      );
    }
    // case: signed in as Employee
    else if (this.props.isSignedIn === true && this.props.accountType === "Employee") {
      return (
        <div>
          <h1>404 PAGE NOT FOUND</h1>
          <br></br>
          <Link style={{ textDecoration: "none" }} to="/employee-dashboard">
            <Button variant="contained" color="secondary">
              Click here to be redirected to the Employee Dashboard Page
            </Button>
          </Link>
        </div>
      );
    }
    // case: signed in but not as a valid accountType (should be impossible)
    // the button here resets the App to a default state
    else {
      return (
        <div>
          <h1>404 PAGE NOT FOUND</h1>
          <br></br>
          <Button variant="contained" color="secondary" onClick={this.resetAppState}>
            Click here to be reset the state of the app
          </Button>
        </div>
      );
    }
  }
}
