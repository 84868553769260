import React, { useState } from "react";
import { Route, Link, Redirect, useRouteMatch } from "react-router-dom";
import { Grid, Paper, Container, Typography, Button, ButtonGroup } from "@material-ui/core";
import "ag-grid-community/dist/styles/ag-grid.css";
import "ag-grid-community/dist/styles/ag-theme-alpine.css";
import TeachersTable from "./TeachersTable.js";
import StudentsTable from "./StudentsTable.js";
import EmployeesTable from "./EmployeesTable.js";
import UsersTable from "./UsersTable.js";

export default function EmployeeDashboard(props) {
  // variables for handling the nested routes
  let { path, url } = useRouteMatch();

  const { teachers, students, employees, users, functModifyAppState, contacts } = props;

  return (
    <Container style={{ paddingTop: "6vh", paddingBottom: "5vh" }} maxWidth="lg">
      <Grid container spacing={4} direction="row" justify="space-evenly" alignItems="center">
        <Grid item xs={12}>
          <Typography variant="h4">Employee Dashboard</Typography>
        </Grid>
        <Grid item xs={6}>
          <Paper
            elevation={10}
            style={{
              position: "relative",
              padding: "4vw",
            }}
          >
            <ButtonGroup variant="text" color="secondary" aria-label="outlined primary button group">
              <Link style={{ textDecoration: "none" }} to={`${url}/teachers-table`}>
                <Button>Teachers</Button>
              </Link>
              <Link style={{ textDecoration: "none" }} to={`${url}/students-table`}>
                <Button>Students</Button>
              </Link>
              <Link style={{ textDecoration: "none" }} to={`${url}/employees-table`}>
                <Button>Employees</Button>
              </Link>
              <Link style={{ textDecoration: "none" }} to={`${url}/users-table`}>
                <Button>Users</Button>
              </Link>
            </ButtonGroup>
          </Paper>
        </Grid>
        <Grid item style={{ width: "100%" }}>
          <Route exact path={path} render={() => <Redirect to={`${path}/teachers-table`} />} />
          <Route path={`${path}/teachers-table`}>
            <TeachersTable teachers={teachers} functModifyAppState={functModifyAppState} />
          </Route>
          <Route path={`${path}/students-table`}>
            <StudentsTable students={students} />
          </Route>
          <Route path={`${path}/employees-table`}>
            <EmployeesTable employees={employees} functModifyAppState={functModifyAppState} />
          </Route>
          <Route path={`${path}/users-table`}>
            <UsersTable users={users} />
          </Route>
        </Grid>
      </Grid>
    </Container>
  );
}
