import React from "react";
import { Link } from "react-router-dom";
import { AgGridReact } from "ag-grid-react";
import { Container, Button, Grid, Paper, Tooltip, IconButton } from "@material-ui/core";
import PersonAddSharpIcon from "@material-ui/icons/PersonAddSharp";
import EditIcon from "@material-ui/icons/Edit";
import AddCircleOutlineOutlinedIcon from "@material-ui/icons/AddCircleOutlineOutlined";
import "ag-grid-community/dist/styles/ag-grid.css";
import "ag-grid-community/dist/styles/ag-theme-alpine.css";

const columns = [
  {
    headerName: "First",
    field: "fname",
    checkboxSelection: true,
  },
  {
    headerName: "Last",
    field: "lname",
  },
  {
    headerName: "Phone",
    field: "phone",
  },
  {
    headerName: "Email",
    field: "email",
  },
  {
    headerName: "Address",
    field: "street",
  },
  {
    headerName: "City",
    field: "city",
  },
  {
    headerName: "State",
    field: "state",
  },
  {
    headerName: "Zip",
    field: "zip",
  },
  {
    headerName: "Birthday",
    field: "birthdate",
    filter: "agDateColumnFilter",
  },
  {
    headerName: "Hire Date",
    field: "hiredate",
    filter: "agDateColumnFilter",
  },
  {
    headerName: "Exit Date",
    field: "exitdate",
    filter: "agDateColumnFilter",
  },
];

const defaultColDef = {
  sortable: true,
  editable: true,
  filter: true,
  floatingFilter: true,
};

export default function EmployeeTable(props) {
  const { employees } = props;
  let gridApi;
  const onGridReady = (params) => {
    gridApi = params.api;
  };
  const onExportClick = () => {
    gridApi.exportDataAsCsv();
  };
  return (
    <Container style={{ paddingTop: "6vh", paddingBottom: "5vh" }} maxWidth="lg">
      <Grid item style={{ width: "100%", paddingBottom: "0px" }}>
        <div className="Add Employee Button" style={{ float: "right" }}>
          <Tooltip title="Add Employee" arrow>
            <Link to="/add-employee">
              <IconButton aria-label="PersonAddSharp">
                <PersonAddSharpIcon />
              </IconButton>
            </Link>
          </Tooltip>
        </div>
      </Grid>
      <Grid container direction="row" justify="space-evenly" alignItems="center">
        <Paper
          elevation={10}
          style={{
            position: "relative",
            alignContent: "center",
            height: "100%",
            width: "100%",
          }}
        >
          <div className="ag-theme-alpine" style={{ height: "50vh", width: "100%", textAlign: "left" }}>
            <AgGridReact rowData={employees} columnDefs={columns} defaultColDef={defaultColDef} onGridReady={onGridReady} />
          </div>
          <div style={{ padding: "2vh" }}>
            <Button variant="contained" color="secondary" onClick={() => onExportClick()}>
              Export
            </Button>
          </div>
        </Paper>
      </Grid>
    </Container>
  );
}
