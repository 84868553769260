import React, { Component, useState, useEffect, useRef } from "react";
import { Link, Redirect } from "react-router-dom";
import { Button, Typography, makeStyles, Paper } from "@material-ui/core";
import TextField from "@material-ui/core/TextField";
import Axios from "axios";
import Container from "@material-ui/core/Container";
import Grid from "@material-ui/core/Grid";
//Imports I placed when trying to change Birthday textfield to Picker 
//import 'date-fns';
//import DateFnsUtils from "@date-io/date-fns";
//import KeyboardDatePicker from '@material-ui/pickers';

const accountCreationStyles = makeStyles((theme) => ({
    gridContainer: {
        paddingRight: "150px",
    },
    root: {
        height: 100,
        width: 50,
        "& > *": {
            margin: theme.spacing(1),
            width: "25ch",
        },
    },
}));

export default function EditStudentPage(props) {
    // extract the props
    const { studentData, functModifyAppState } = props;

    // state variables for redirecting the page only when ready
    // if the page is ready to be redirected (work successful | cancel work)
    // where to redirect to in each case
    const [pageRedirectState, setPageRedirectState] = useState({
        isPageReadyToBeRedirected: false,
        linkToRedirectTo: null,
    });

    const classes = makeStyles();

    // showConfirmDelete state variable
    const [showConfirmDelete, setShowConfirmDeleteState] = useState(false);

    const startDelete = () => {
        setShowConfirmDeleteState(true);
    };

    const cancelDelete = () => {
        setShowConfirmDeleteState(false);
    };

    // State to know if entries are in error
    const [inError, setInError] = useState(false);

    const changeError = (bo) => {
        setInError(bo);
    };

    //First Hook Block for student First Name
    const [studentFirstName, setStudentFirstName] = useState(() => {
        return "";
    });
    //onChange Handler for Student First Name text field
    const studentFirstNameChange = (e) => {
        changeError(false);
        setStudentFirstName(e.target.value);
    };

      //Hook Block for student Last Name
    const [studentLastName, setStudentLastName] = useState(() => {
        return "";
    });
    //onChange Handler for Student Last Name text field
    const studentLastNameChange = (e) => {
        changeError(false);
        setStudentLastName(e.target.value);
    };

    //Hook for student Birthdate
    const [studentBirthDate, setStudentBirthdate] = useState(() => {
        return "";
    });
    //onChange Handler for Student Birthdate field
    const studentBirthdateChange = (e) => {
        changeError(false);
        setStudentBirthdate(e.target.value);
    };

    //Hook for Medical Info
    const [studentMedicalInfo, setStudentMedicalInfo] = useState(() => {
        return "";
    });
    //onChange Handler for Student Last Name text field
    const studentMedicalInfoChange = (e) => {
        changeError(false);
        setStudentMedicalInfo(e.target.value);
    };

    const deleteStudent = () => {
        Axios({
            method: "GET",
            url: "http://localhost:5000/deleteStudent",
            headers: {
                "Content-Type": "application/json",
            },
            params: {
                // PLACEHOLDER CONTENT SO I CAN CONVERT TO FUNCTION - MUST REPLACE
                userid: 1234,
            },
        }).then((res) => {
            console.log(res.data.message);

            // this needs to only happen if the operation is successful
            // set that the page is ready to be redirected
            setPageRedirectState({
                isPageReadyToBeRedirected: true,
                linkToRedirectTo: "/account",
            });
        });
    };

    /* Need to add onClick to "Confirm" in order to have it check database*/

    // fake function to get the link to work
    const attemptToSaveChanges = () => {
        //Axios Statement to pull current Student info and change based on what the user types into fields
        //So far only contains lines of code to alter first name
        Axios({
            method: "POST",
            url: "http://localhost:5000/editStudent",
            headers: {
                "Content-Type": "application/json",
            },
            data: {
                // PLACEHOLDER CONTENT SO I CAN CONVERT TO FUNCTION - MUST REPLACE
                id: 1234,
                fname: "CHOCOLATE",
                lname: "CHIP",
                birthdate: "PANCAKES",
                medical: "YUM",
                // PLACEHOLDER CONTENT SO I CAN CONVERT TO FUNCTION - MUST REPLACE
            },
        }).then((res) => {
            //handle response here

            // this needs to only happen if the operation is successful
            // set that the page is ready to be redirected
            setPageRedirectState({
                isPageReadyToBeRedirected: true,
                linkToRedirectTo: "/account",
            });

            functModifyAppState({ students: res.data.studentData });
        });

        // this needs to only happen if the operation is successful
        // set that the page is ready to be redirected
    };

    // redirect if the page is ready to be redirected
    if (pageRedirectState.isPageReadyToBeRedirected === true && pageRedirectState.linkToRedirectTo != null) {
        return <Redirect to={pageRedirectState.linkToRedirectTo} />;
    }
    // render the page normally
    else {
        return (
            <Container style={{ paddingTop: "6vh", paddingBottom: "4vh" }} maxWidth="md">
                <Paper
                    elevation={10}
                    style={{
                        position: "relative",
                        padding: "4vw",
                        paddingRight: "4vw",
                        paddingBottom: "5vh",
                    }}
                >
                    <Typography>
                        <h3>Edit Student Details</h3>
                    </Typography>
                    <br></br>
                    <Grid container spacing={3} justify="center" className={classes.gridContainer} gutterBottom>
                        <form className={classes.root} noValidate autoComplete="off">
                            <Grid item xs={12} sm={6} lg={12} alignItems="center">
                                <TextField
                                    id="outlined-basic"
                                    label="First Name"
                                    value={studentFirstName}
                                    onChange={studentFirstNameChange}
                                    variant="outlined"
                                    fullWidth
                                />
                            </Grid>
                            <Grid item xs={12} sm={6} lg={12}>
                                <TextField id="outlined-basic" 
                                           label="Last Name"
                                           value={studentLastName}
                                           onChange={studentLastNameChange} 
                                           variant="outlined" 
                                           fullWidth />
                            </Grid>
                            <Grid item xs={12}>
                                <TextField id="outlined-basic" 
                                           label="Birthday"
                                           value={studentBirthDate}
                                           onChange={studentBirthdateChange} 
                                           variant="outlined" 
                                           fullWidth />
                            </Grid>
                            <Grid item xs={12}>
                                <TextField
                                    id="filled-helperText"
                                    label="Medical Conditions"
                                    value={studentMedicalInfo}
                                    onChange={studentMedicalInfoChange}
                                    helperText="Please Seperate information with commas"
                                    variant="outlined"
                                    fullWidth
                                />
                            </Grid>
                            <br></br>

                            <div>
                                <Button variant="contained" justify="center" onClick={attemptToSaveChanges}>
                                    Save
                                </Button>
                            </div>
                            <div></div>
                            <div>
                                <Button variant="contained" color="secondary" onClick={startDelete}>
                                    Delete
                                </Button>
                            </div>
                        </form>
                    </Grid>
                    {showConfirmDelete && (
                        <div>
                            <Button variant="contained" onClick={deleteStudent}>
                                Confirm
                            </Button>
                            <Button variant="contained" color="secondary" onClick={cancelDelete}>
                                Cancel
                            </Button>
                        </div>
                    )}

                    <br></br>
                    <br></br>
                </Paper>
            </Container>
        );
    }
}
