import React, { Component } from "react";
import { Link } from "react-router-dom";
import { Button, Typography, Grid, Paper, Container } from "@material-ui/core";

export default function AccountCreatedPage(props) {
    const { userData, functLoadAddAddtionalInformationPage } = props;
    const { ID, email, pass, fname, lname, street, city, state, zip, birthdate, phone, resetPasswordToken, googleLogin } = userData;
    return (
        <div>
            <Container style={{ paddingTop: "6vh", paddingBottom: "5vh" }} maxWidth="md">
                <Paper
                    elevation={10}
                    style={{
                        position: "relative",
                        padding: "4vw",
                    }}
                >
                    <Grid container spacing={4}>
                        <Grid item xs={12}>
                            <Typography variant="h4">Welcome, {this.props.userName}! Your account has been created successfully.</Typography>
                        </Grid>
                        <Grid item xs={12}>
                            <Typography variant="h5">You have been signed in automatically.</Typography>
                        </Grid>
                        <Grid item xs={12}>
                            <Typography variant="h5">In order to use this app you need to have at least one contact on file.</Typography>
                        </Grid>
                        <Grid item xs={12}>
                            <Link to="/add-an-account-contact">
                                <Button variant="contained" color="secondary">
                                    Click here to Add An Account Contact now.
                                </Button>
                            </Link>
                        </Grid>
                        <Grid item xs={12}>
                            <Typography variant="h5">OR</Typography>
                        </Grid>
                        <Grid item xs={12}>
                            <Link to="/account">
                                <Button variant="contained" color="secondary">
                                    Click here to be taken to your Account Page.
                                </Button>
                            </Link>
                        </Grid>
                    </Grid>
                </Paper>
            </Container>
        </div>
    );
}
