import React, { useState, useEffect, useRef } from "react";
import { Link, Redirect } from "react-router-dom";
import { Button, Typography, makeStyles, TextField, Paper, Container, Grid } from "@material-ui/core";
import Axios from "axios";

const accountCreationStyles = makeStyles((theme) => ({
    root: {
        height: 100,
        width: 50,
        "& > *": {
            margin: theme.spacing(1),
            width: "25ch",
        },
    },
}));
export default function EditPasswordPage(props) {
    // DeConstruct Props
    const { accountData, functModifyAppState } = props;

    // This functions hadels key presses
    const useKey = (key, cb) => {
        const callBackRef = useRef(cb);
        useEffect(() => {
            callBackRef.current = cb;
        });
        useEffect(() => {
            const handle = (event) => {
                if (event.code === key) {
                    callBackRef.current(event);
                }
            };
            document.addEventListener("keypress", handle);
            return () => document.removeEventListener("keypress", handle);
        }, [key]);
    };
    // Callback Function
    const handleEnter = () => {
        handleConfirm();
    };
    // Handle enter
    useKey("Enter", handleEnter);

    // State for the 3 fields
    const [currentPass, setCurrentPass] = useState(() => {
        return "";
    });
    const changeCurrentPass = (e) => {
        setCurrentPass(e.target.value);
    };

    const [newPass, setNewPass] = useState(() => {
        return "";
    });
    const changeNewPass = (e) => {
        setNewPass(e.target.value);
    };

    const [confNewPass, setConf] = useState(() => {
        return "";
    });
    const changeConfNewPass = (e) => {
        setConf(e.target.value);
    };

    const [isPageReadyToBeRedirected, setRedirectState] = useState(() => {
        return false;
    });
    const changeRedirectState = () => {
        setRedirectState(true);
    };
    // Redirect Location
    const [linkToRedirectTo, setLink] = useState(() => {
        return null;
    });
    const changeLink = (lin) => {
        setLink(lin);
    };

    // Handler for confirm button press
    const handleConfirm = () => {
        if ((currentPass !== "" || accountData.googleLogin === 1) && newPass !== "" && newPass === confNewPass) {
            editPass(currentPass, newPass);
        } else {
            console.log("Entry error");
        }
    };

    const editPass = (currentPass, newPass) => {
        Axios({
            method: "GET",
            url: "http://localhost:5000/changePassword",
            headers: {
                "Content-Type": "application/json",
            },
            params: {
                email: accountData.email,
                curPass: currentPass,
                newPass: newPass,
            },
        })
            .then((res) => {
                if (res.data.message === "Current Password is wrong!") {
                    console.log(res.data.message);
                } else if (res.data.message === "Password Changed Successfully") {
                    accountData.pass = newPass;
                    functModifyAppState({ accountData });
                    changeRedirectState();
                    changeLink("/account");
                }
            })
            .catch((error) => {
                console.log(error.data);
            });
    };

    // redirect if the page is ready to be redirected
    if (isPageReadyToBeRedirected === true && linkToRedirectTo !== null) {
        return <Redirect to={linkToRedirectTo} />;
    }
    // render the page normally
    else {
        return (
            <div className={accountCreationStyles.root}>
                <Container style={{ paddingTop: "6vh", paddingBottom: "5vh" }} maxWidth="sm">
                    <Paper
                        elevation={10}
                        style={{
                            position: "relative",
                            padding: "4vw",
                        }}
                    >
                        <Grid container spacing={3}>
                            <Grid item xs={12}>
                                <Typography>
                                    <h4>Edit Password</h4>
                                </Typography>
                            </Grid>
                            <Grid item xs={12}>
                                <TextField label="Current Password" value={currentPass} onChange={changeCurrentPass} variant="outlined" />
                            </Grid>
                            <Grid item xs={12}>
                                <TextField label="New Password" value={newPass} onChange={changeNewPass} variant="outlined" />
                            </Grid>
                            <Grid item xs={12}>
                                <TextField label="Confirm Password" value={confNewPass} onChange={changeConfNewPass} variant="outlined" />
                            </Grid>
                            <Grid item xs={12}>
                                <Button variant="contained" onClick={handleConfirm}>
                                    Confirm
                                </Button>
                            </Grid>
                            <Grid item xs={12}>
                                <Link style={{ textDecoration: "none" }} to="edit-account">
                                    <Button variant="contained" color="secondary">
                                        Cancel
                                    </Button>
                                </Link>
                            </Grid>
                        </Grid>
                    </Paper>
                </Container>
            </div>
        );
    }
}
