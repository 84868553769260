import React, { useState, useEffect, useRef } from "react";
import { Link, Redirect } from "react-router-dom";
import { Button, Typography, Paper, TextField, Grid, Container } from "@material-ui/core";
import Axios from "axios";
import crypto from "crypto";
import { GoogleLogin } from "react-google-login";

const CLIENT_ID = "609210955285-eokdrnl1p2e59mgi81ol5o45cratn3j5.apps.googleusercontent.com";

export default function CreateAccountPage(props) {
    const { functModifyAppState } = props;
    // This functions hadels key presses
    const useKey = (key, cb) => {
        const callBackRef = useRef(cb);
        useEffect(() => {
            callBackRef.current = cb;
        });
        useEffect(() => {
            const handle = (event) => {
                if (event.code === key) {
                    callBackRef.current(event);
                }
            };
            document.addEventListener("keypress", handle);
            return () => document.removeEventListener("keypress", handle);
        }, [key]);
    };
    // Callback Function
    const handleEnter = () => {
        handleCreate();
    };
    // Handle enter
    useKey("Enter", handleEnter);

    const [isPageReadyToBeRedirected, setRedirectState] = useState(() => {
        return false;
    });
    const changeRedirectState = () => {
        setRedirectState(true);
    };

    const [linkToRedirectTo, setLink] = useState(() => {
        return null;
    });
    const changeLink = (lin) => {
        setLink(lin);
    };

    const [creationStarted, setStarted] = useState(() => {
        return false;
    });

    const changeStarted = () => {
        setStarted(true);
    };

    const [email, setEmail] = useState(() => {
        return "";
    });

    const emailChanged = (e) => {
        setEmail(e.target.value);
        setInError(false);
        setAttemptCreate(false);
    };
    const [password, setPass] = useState(() => {
        return "";
    });
    const passwordChanged = (e) => {
        setPass(e.target.value);
        setInError(false);
        setAttemptCreate(false);
    };
    const [confirmPass, setConfirmPass] = useState(() => {
        return "";
    });
    const confirmPassChanged = (e) => {
        setConfirmPass(e.target.value);
        setInError(false);
        setAttemptCreate(false);
    };
    const [attemptedCreate, setAttemptCreate] = useState(() => {
        return false;
    });

    const goHome = () => {
        changeRedirectState();
        changeLink("/welcome");
    };

    const handleCreate = () => {
        setAttemptCreate(true);
        attemptToCreateAccount(email, password, confirmPass);
    };

    const [inError, setInError] = useState(() => {
        return false;
    });

    const getError = (val, field) => {
        if (val === "" && attemptedCreate && inError) {
            return inError;
        } else if (field === "emailAddress" && !isEmail(val) && attemptedCreate && inError) {
            return inError;
        } else if (field === "confirmPassword" && password !== confirmPass && attemptedCreate && inError) {
            return inError;
        } else {
            return inError;
        }
    };
    const isEmail = (val) => {
        const re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
        return re.test(String(val).toLowerCase());
    };

    const getHelper = (val, field) => {
        if (val === "" && attemptedCreate) {
            return "Field Required";
        } else if (field === "emailAddress" && !isEmail(val) && attemptedCreate) {
            return "Check Email Format";
        } else if ((field === "confirmPassword" || field === "password") && password !== confirmPass && attemptedCreate) {
            return "Passwords Must Match";
        } else {
            return "";
        }
    };

    //function for google sign in
    const googleCreate = (res) => {
        console.log("current user: ", res.profileObj);
        const email = res.profileObj.email;
        Axios({
            method: "POST",
            url: "http://localhost:5000/handleGoogleCreate",
            headers: {
                "Content-Type": "application/json",
            },
            data: {
                email: email,
            },
        })
            .then((res) => {
                if (res.data.message === "Creating New Account") {
                    let acctD = res.data.userData;
                    acctD.accountType = "Customer";
                    functModifyAppState({ isSignedIn: true, accountData: acctD });
                    console.log(res.data.message);
                    console.log("Go to First time account Creation");
                    changeRedirectState();
                    changeLink("/complete-account-creation");
                } else if (res.data.message === "Account Exists") {
                    console.log(res.data.message);
                    setInError(true);
                } else {
                    console.log(res.data.message);
                    console.log("Google Create Account Error");
                    setInError(true);
                }
            })
            .catch((error) => {
                console.log(error.data);
            });
    };

    const handleGoogleCreateFailure = () => {
        console.log("Failed to log into your google account");
        setInError(true);
    };

    const attemptToCreateAccount = (em, pass, confPass) => {
        var token = crypto.randomBytes(8).toString("hex");

        if (em !== "" && pass !== "" && pass === confPass && isEmail(em)) {
            // DATABASE LADS DO THINGS HERE
            Axios({
                method: "GET",
                url: "http://localhost:5000/createaccount",
                headers: {
                    "Content-Type": "application/json",
                },
                params: {
                    email: em,
                    password: pass,
                    token: token,
                },
            })
                .then((res) => {
                    if (res.data.message === "user exists") {
                        console.log("From CreateAccountPage: user exists, need to inform user");
                        setInError(true);
                    } else if (res.data.message === "success") {
                        console.log("From CreateAccountPage: user doesn't exist, creating account");
                        changeStarted();
                    } else {
                        console.log("Create Account failed");
                    }
                })
                .catch((error) => {
                    console.log(error.data);
                });
        } else {
            console.log("Password and confirm pass don't match, or cant be empty");
            setInError(true);
        }
    };

    // redirect if the page is ready to be redirected
    if (isPageReadyToBeRedirected === true && linkToRedirectTo !== null) {
        return <Redirect to={linkToRedirectTo} />;
    }

    if (!creationStarted) {
        // render the page normally
        return (
            <div className="Create Account Page">
                <Container style={{ paddingTop: "6vh", paddingBottom: "5vh" }} maxWidth="sm">
                    <Paper
                        elevation={10}
                        style={{
                            position: "relative",
                            padding: "4vw",
                        }}
                    >
                        <Typography variant="h5">Create Account</Typography>
                        <br></br>
                        <Grid container spacing={3}>
                            <Grid item xs={12}>
                                <TextField
                                    variant="outlined"
                                    id="emailField"
                                    name="emailAddress"
                                    label="Email"
                                    fullWidth
                                    autoComplete="email"
                                    required
                                    onChange={emailChanged}
                                    value={email}
                                    error={getError(email, "emailAddress")}
                                    helperText={getHelper(email, "emailAddress")}
                                />
                            </Grid>
                            <Grid item xs={12}>
                                <TextField
                                    variant="outlined"
                                    id="passwordField"
                                    name="password"
                                    label="Create Password"
                                    fullWidth
                                    autoComplete="createPassword"
                                    required
                                    onChange={passwordChanged}
                                    value={password}
                                    error={getError(password, "password")}
                                    helperText={getHelper(password, "password")}
                                />
                            </Grid>
                            <Grid item xs={12}>
                                <TextField
                                    variant="outlined"
                                    id="confirmPasswordField"
                                    name="confirmPassword"
                                    label="Confirm Password"
                                    fullWidth
                                    autoComplete="createPassword"
                                    required
                                    onChange={confirmPassChanged}
                                    value={confirmPass}
                                    error={getError(confirmPass, "confirmPassword")}
                                    helperText={getHelper(confirmPass, "confirmPassword")}
                                />
                            </Grid>
                            <Grid item xs={12}>
                                <Button variant="contained" color="secondary" onClick={handleCreate}>
                                    Create Account
                                </Button>
                            </Grid>
                            <Grid item xs={12}>
                                <GoogleLogin
                                    clientId={CLIENT_ID}
                                    buttonText="Create Account"
                                    onSuccess={googleCreate}
                                    onFailure={handleGoogleCreateFailure}
                                    cookiePolicy={"single_host_origin"}
                                    responseType="code,token"
                                />
                            </Grid>
                            <Grid item xs={12}>
                                <Link to="/sign-in">Have an account? Click here to sign in!</Link>
                            </Grid>
                        </Grid>
                    </Paper>
                </Container>
            </div>
        );
    } else {
        return (
            <div className="Create Account Page">
                <Container style={{ paddingTop: "6vh", paddingBottom: "5vh" }} maxWidth="sm">
                    <Paper
                        elevation={10}
                        style={{
                            position: "relative",
                            padding: "4vw",
                        }}
                    >
                        <Typography variant="h5">Create Account</Typography>
                        <br></br>
                        <Grid container spacing={3}>
                            <Grid item xs={12}>
                                <Typography variant="body2" color="textSecondary" component="p">
                                    We sent you an email to confirm your email address.
                                </Typography>
                            </Grid>
                            <Grid item xs={12}>
                                <Button variant="contained" color="secondary" onClick={goHome}>
                                    Go home
                                </Button>
                            </Grid>
                        </Grid>
                    </Paper>
                </Container>
            </div>
        );
    }
}
