import React, { useState, useEffect, useRef } from "react";
import { makeStyles } from "@material-ui/core/styles";
import { Grid, Typography, TextField, Paper, Button } from "@material-ui/core/";



const useStyles = makeStyles({
  paper: {
    maxWidth: "-webkit-fill-available",
    minWidth: "-webkit-fill-available",
  },
  noMargin: {
    margin: "0px",
  },
});

export default function AccCreAccInfPrimaryContactInformation(props){
  // styling
  const classes = useStyles();

  // the data from the text fields
  // firstName state variable
  const [firstName, setFirstNameState] = useState(() => {
    return props.primaryContactInformationState.fname;
  });

  // firstName onChange handler
  const firstNameChanged = (e) => {
    changeError(false);
    setAttemptedToSaveData(false);
    setFirstNameState(e.target.value);
  };
  
  // lastName state variable
  const [lastName, setLastNameState] = useState(() => {
    return props.primaryContactInformationState.lname;
  });

  // lastName onChange handler
  const lastNameChanged = (e) => {
    changeError(false);
    setAttemptedToSaveData(false);
    setLastNameState(e.target.value);
  };
  
  // primaryPhoneNumber state variable
  const [primaryPhoneNumber, setPrimaryPhoneNumberState] = useState(() => {
    return props.primaryContactInformationState.cellPhone;
  });

  // primaryPhoneNumber onChange handler
  const primaryPhoneNumberChanged = (e) => {
    changeError(false);
    setAttemptedToSaveData(false);
    setPrimaryPhoneNumberState(e.target.value);
  };
  
  // secondayPhoneNumber state variable (optional)
  const [secondaryPhoneNumber, setSecondaryPhoneNumberState] = useState(() => {
    return props.primaryContactInformationState.homePhone;
  });

  // secondayPhoneNumber onChange handler
  const secondaryPhoneNumberChanged = (e) => {
    changeError(false);
    setAttemptedToSaveData(false);
    setSecondaryPhoneNumberState(e.target.value);
  };
  
  // address state variable
  const [address, setAddressState] = useState(() => {
    return props.primaryContactInformationState.street;
  });

  // streetAddress onChange handler
  const addressChanged = (e) => {
    changeError(false);
    setAttemptedToSaveData(false);
    setAddressState(e.target.value);
  };
  
  // city state variable
  const [city, setCityState] = useState(() => {
    return props.primaryContactInformationState.city;
  });

  // city onChange handler
  const cityChanged = (e) => {
    changeError(false);
    setAttemptedToSaveData(false);
    setCityState(e.target.value);
  };
  
  // state state variable
  const [state, setStateState] = useState(() => {
    return props.primaryContactInformationState.state;
  });

  // state onChange handler
  const stateChanged = (e) => {
    changeError(false);
    setAttemptedToSaveData(false);
    setStateState(e.target.value);
  };
  
  // zip state variable
  const [zip, setZipState] = useState(() => {
    return props.primaryContactInformationState.zip;
  });

  // zip onChange handler
  const zipChanged = (e) => {
    changeError(false);
    setAttemptedToSaveData(false);
    setZipState(e.target.value);
  };
  
  // This functions handles key presses
  const useKey = (key, cb) => {
    const callBackRef = useRef(cb);
    useEffect(() => {
      callBackRef.current = cb;
    });
    useEffect(() => {
      const handle = (event) => {
        if (event.code === key) {
          callBackRef.current(event);
        }
      };
      document.addEventListener("keypress", handle);
      return () => document.removeEventListener("keypress", handle);
    }, [key]);
  };
  // Callback Function
  const handleEnter = () => {
    handleComplete();
  };
  // Handle enter
  useKey("Enter", handleEnter);

  // check to see if the text fields are filled out properly
  // if they are then return FALSE to allow the "Accept & Continue" button to work
  // else return TRUE to disable the "Accept & Continue" button
  const checkIfIncomplete = () => {
    // if the required fields are filled out properly, return false
    if(
      firstName !== "" &&
      lastName !== "" &&
      primaryPhoneNumber !== "" &&
      address !== "" &&
      city !== "" &&
      state !== "" &&
      zip !== ""
    ){
      return false;
    }
    // else if the fields aren't filled out properly, return true
    else {
      return true;
    }
  };

  // set the appropriate top level state with the data from this page
  const setPrimaryContactInformation = () => {
    // collect each state variable and combine them into a single object
    const primaryContactInformationObj = {
      id: props.accountData.ID,
      fname: firstName,
      lname: lastName,
      cellPhone: primaryPhoneNumber,
      homePhone: secondaryPhoneNumber,
      street: address,
      city: city,
      state: state,
      zip: zip
    }

    // set the top level state to hold the data from this page
    props.functSetPrimaryContactInformationState(primaryContactInformationObj);
  }

  // save the data from the text fields before moving to the next step
  const handleComplete = () => {
    // save the fields to the top level
    setPrimaryContactInformation();

    // step forward
    props.functHandleComplete();
  }

  // State to know if entries are in error
  const [inError, setInError] = useState(() => {
    return false;
  });
  const changeError = (bo) => {
    setInError(bo);
  };

  // Did the user try to Add a Student?
  const [attemptedToSaveData, setAttemptedToSaveData] = useState(() => {
    return false;
  });

  // Set fields to be in error conditionally
  const getError = (val) => {
    if (val === "" && attemptedToSaveData && inError) {
      return true;
    } 
    else if (attemptedToSaveData && inError) {
      return false;
    } 
    // else {
    //   return inError;
    // }
  };

  // Get helper text for fields if in error
  const getHelper = (val) => {
    if (val === "" && attemptedToSaveData && inError) {
      return "Required Field";
    } else {
      return "";
    }
  };


  return (
    <Grid container direction="row" justify="space-evenly" alignItems="center">
      <Grid item xs={false} sm={false} md={false} lg={2} />
      <Grid
        item
        container
        xs={12}
        sm={12}
        md={12}
        lg={8}
        spacing={5}
        direction="column"
        justify="center"
        alignItems="center"
        style={{ paddingTop: "6vh" }}
        classes={{ "spacing-xs-5": classes.noMargin }}
      >
        <Paper elevation={5} style={{ padding: " 5vh 10vw" }}>
          <Grid item>
            <Typography variant="h4">Primary Contact Information</Typography>
          </Grid>
          <Grid container spacing={3} classes={{ "spacing-xs-3": classes.noMargin }}>
            <Grid item xs={12} sm={6}>
              <TextField 
                required 
                id="First Name" 
                name="firstName" 
                label="First Name" 
                value={firstName} 
                onChange={firstNameChanged} 
                fullWidth 
                error={getError(firstName)}
                helperText={getHelper(firstName)}
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <TextField 
                required 
                id="Last Name" 
                name="lastName" 
                label="Last Name" 
                value={lastName} 
                onChange={lastNameChanged} 
                fullWidth 
                error={getError(lastName)}
                helperText={getHelper(lastName)}
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <TextField 
                required 
                id="Primary Phone Number" 
                name="primaryPhoneNumber" 
                label="Primary Phone Number" 
                placeholder="(000)000-0000"
                value={primaryPhoneNumber} 
                onChange={primaryPhoneNumberChanged} 
                fullWidth 
                error={getError(primaryPhoneNumber)}
                helperText={getHelper(primaryPhoneNumber)}
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <TextField 
                id="Secondary Phone Number" 
                name="secondaryPhoneNumber" 
                label="Secondary Phone Number" 
                placeholder="(000)000-0000"
                value={secondaryPhoneNumber} 
                onChange={secondaryPhoneNumberChanged} 
                fullWidth 
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <TextField 
                required 
                id="Address" 
                name="address" 
                label="Address" 
                value={address} 
                onChange={addressChanged} 
                fullWidth 
                error={getError(address)}
                helperText={getHelper(address)}
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <TextField 
                required 
                id="City" 
                name="city" 
                label="City" 
                value={city} 
                onChange={cityChanged} 
                fullWidth 
                error={getError(city)}
                helperText={getHelper(city)}
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <TextField 
                required 
                id="State" 
                name="state" 
                label="State" 
                value={state} 
                onChange={stateChanged} 
                fullWidth 
                error={getError(state)}
                helperText={getHelper(state)}
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <TextField 
                required 
                id="Zip" 
                name="zip" 
                label="Zip" 
                value={zip} 
                onChange={zipChanged} 
                fullWidth 
                error={getError(zip)}
                helperText={getHelper(zip)}
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <Button variant="contained" color="secondary" onClick={props.functHandleBack}>
                {props.previousStepButtonString}
              </Button>
            </Grid>
            <Grid item xs={12} sm={6}>
              <Button disabled={checkIfIncomplete()} variant="contained" color="secondary" onClick={handleComplete}>
                {props.saveStepButtonString}
              </Button>
            </Grid>
          </Grid>
        </Paper>
      </Grid>
      <Grid item xs={false} sm={false} md={false} lg={2} />
    </Grid>
  );
}
