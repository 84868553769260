import React, { Component } from "react";
import { Link } from "react-router-dom";
import { Container, Typography, Paper, Button, Grid } from "@material-ui/core";

export default class WelcomePage extends Component {
  render() {
    return (
      <div>
        <Container style={{ paddingTop: "6vh", paddingBottom: "4vh" }} maxWidth="md">
          <Paper
            elevation={10}
            style={{
              position: "relative",
              padding: "4vw",
            }}
          >
            <br></br>
            <Typography variant="h2" style={{ align: "center" }}>
              Welcome!
            </Typography>
            <Typography
              variant="body1"
              style={{
                paddingLeft: "6vw",
                paddingRight: "6vw",
                paddingTop: "4vh",
                paddingBottom: "4vh",
              }}
            >
              Welcome to the new Tremolo app brought to you by McLaughlin Studios. To create an account, click the "Get Started" button below. If you
              already have an account, continue to sign in.
            </Typography>
            <Grid container spacing={3} direction="column">
              <Grid item xs={12}>
                <Link style={{ textDecoration: "none" }} to="/create-account">
                  <Button variant="contained" color="secondary">
                    Get Started
                  </Button>
                </Link>
              </Grid>
              <Grid item xs={12}>
                <Link style={{ textDecoration: "none" }} to="/sign-in">
                  <Button variant="contained" color="secondary">
                    Sign In
                  </Button>
                </Link>
              </Grid>
            </Grid>
          </Paper>
        </Container>
      </div>
    );
  }
}
