import React from "react";
import { Link } from "react-router-dom";
import { Button } from "@material-ui/core";

export default function HeaderButtons(props) {
  
    // case: not signed in
    if (props.isSignedIn === false) {
        // There are no header buttons to display when not signed in
        return (
            <div className="Header Buttons Not Signed In" style={{ padding: "1em" }}>
                <Link to="/test-source-page">
                    <Button color="default">Test Source Page</Button>
                </Link>
                <Link to="/test-target-page">
                    <Button color="default">Test Target Page</Button>
                </Link>
            </div>
        );
    }
    // case: signed in as a Customer
    else if (props.isSignedIn === true && props.accountType === "Customer") {
        return (
            <div className="Header Buttons Customer Signed In" style={{ padding: "1em" }}>
                <Link to="/account">
                    <Button color="default">My Account</Button>
                </Link>
                <Link to="/teachers">
                    <Button color="default">Meet the Teachers</Button>
                </Link>
            </div>
        );
    }
    // case: signed in as an Employee
    else if (props.isSignedIn === true && props.accountType === "Employee") {
        return (
            <div className="Header Buttons Employee Signed In" style={{ padding: "1em" }}>
                <Link to="/employee-dashboard">
                    <Button color="default">Employee Dashboard</Button>
                </Link>
            </div>
        );
    }
    // case: signed in but not as a valid accountType (should be impossible)
    else {
        // display nothing
        return null;
    }
}
