import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import { Grid, Typography, Paper, Button } from "@material-ui/core/";

const useStyles = makeStyles({
  paper: {
    maxWidth: "-webkit-fill-available",
    minWidth: "-webkit-fill-available",
  },
  noMargin: {
    margin: "0px",
  },
});

export default function AccCreDanPolAdditionalCostumeInformation(props){
  // styling
  const classes = useStyles();

  // strings that hold the text content - this is to keep the return statement clean
  const stringOne = "McLaughlin Studios is not responsible for the storage of your dancer's costume once it has been paid for. McLaughlin Studios is also not responsible for any alterations your costume may need.";

  return (
    <Grid container direction="row" justify="space-evenly" alignItems="center">
      <Grid item xs={false} sm={false} md={false} lg={2} />
      <Grid
        item
        container
        xs={12}
        sm={12}
        md={12}
        lg={8}
        spacing={5}
        direction="column"
        justify="center"
        alignItems="center"
        style={{ paddingTop: "6vh" }}
        classes={{ "spacing-xs-5": classes.noMargin }}
      >
        <Paper elevation={5} style={{ padding: " 5vh 10vw" }}>
          <Grid item>
            <Typography variant="h4">Additional Costume Information</Typography>
          </Grid>
          <Grid container spacing={3} classes={{ "spacing-xs-3": classes.noMargin }}>
            <Grid item xs={12}>
              <Typography>{stringOne}</Typography>
            </Grid>
            <Grid item xs={12} sm={6}>
              <Button variant="contained" color="secondary" onClick={props.functHandleBack}>
                {props.previousStepButtonString}
              </Button>
            </Grid>
            <Grid item xs={12} sm={6}>
              <Button variant="contained" color="secondary" onClick={props.functHandleComplete}>
                {props.nextStepButtonString}
              </Button>
            </Grid>
          </Grid>
        </Paper>
      </Grid>
      <Grid item xs={false} sm={false} md={false} lg={2} />
    </Grid>
  );
}
