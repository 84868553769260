import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import { Grid, Typography, Paper, Button } from "@material-ui/core/";

const useStyles = makeStyles({
  paper: {
    maxWidth: "-webkit-fill-available",
    minWidth: "-webkit-fill-available",
  },
  noMargin: {
    margin: "0px",
  },
});

export default function AccCreDanPolCostumeFee(props){
  // styling
  const classes = useStyles();

  // strings that hold the text content - this is to keep the return statement clean
  const stringOne = "Costumes will be required for all students for the recitals and class photos. This generally will not apply to adult classes. Costumes will be ordered several months in advance so that they will arrive at the studio in a timely manner (and we can have pictures done early). A non-refundable costume deposit of $20.00 will be charged at time of enrollment (for each class the student is enrolled in). Students who enroll after the first month of the session may have to pay an extra shipping fee – or higher costume fee.";
  const stringTwo = "Please note: Tuition balances must be paid in full to receive costumes.";

  return (
    <Grid container direction="row" justify="space-evenly" alignItems="center">
      <Grid item xs={false} sm={false} md={false} lg={2} />
      <Grid
        item
        container
        xs={12}
        sm={12}
        md={12}
        lg={8}
        spacing={5}
        direction="column"
        justify="center"
        alignItems="center"
        style={{ paddingTop: "6vh" }}
        classes={{ "spacing-xs-5": classes.noMargin }}
      >
        <Paper elevation={5} style={{ padding: " 5vh 10vw" }}>
          <Grid item>
            <Typography variant="h4">Costume Fee</Typography>
          </Grid>
          <Grid container spacing={3} classes={{ "spacing-xs-3": classes.noMargin }}>
            <Grid item xs={12}>
              <Typography>{stringOne}</Typography>
            </Grid>
            <Grid item xs={12}>
              <Typography>{stringTwo}</Typography>
            </Grid>
            <Grid item xs={12} sm={6}>
              <Button variant="contained" color="secondary" onClick={props.functHandleBack}>
                {props.previousStepButtonString}
              </Button>
            </Grid>
            <Grid item xs={12} sm={6}>
              <Button variant="contained" color="secondary" onClick={props.functHandleComplete}>
                {props.nextStepButtonString}
              </Button>
            </Grid>
          </Grid>
        </Paper>
      </Grid>
      <Grid item xs={false} sm={false} md={false} lg={2} />
    </Grid>
  );
}
