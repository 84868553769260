import React from "react";
import { Link } from "react-router-dom";
import { Button, Typography } from "@material-ui/core";

export default function HeaderAccountRegion(props) {

  // function for signing out
  const attemptToSignOut = () => {
    // reset the app state to the default state
    props.functModifyAppState({
      isSignedIn: false,
      accountData: {},
      students: [],
      contacts: [],
      tempStudent: {},
    });
  }

  // case: not signed in
  if (props.isSignedIn === false) {
    return (
      <div className="HeaderAccountRegion" style={{ position: "absolute", right: "4em" }}>
        <Link style={{ textDecoration: "none" }} to="/create-account">
          <Button color="default">Create Account</Button>
        </Link>
        <Link style={{ textDecoration: "none" }} to="/sign-in">
          <Button variant="contained" color="default">
            Sign In
          </Button>
        </Link>
      </div>
    );
  }
  // case: signed in as a Customer
  else if (props.isSignedIn === true && props.accountType === "Customer") {
    return (
      <div className="HeaderAccountRegion" style={{ position: "absolute", right: "4em" }}>
        <Typography color="default">
          Welcome, {props.userName}
        </Typography>
        <Link style={{ textDecoration: "none" }} to="/signed-out">
          <Button color="default" variant="contained" onClick={attemptToSignOut}>
            Sign Out
          </Button>
        </Link>
      </div>
    );
  }
  // case: signed in as an Employee
  else if (props.isSignedIn === true && props.accountType === "Employee") {
    return (
      <div className="HeaderAccountRegion" style={{ position: "absolute", right: "4em" }}>
        <Typography color="default">
          Welcome, {props.userName}
        </Typography>
        <Link style={{ textDecoration: "none" }} to="/signed-out">
          <Button color="default" variant="contained" onClick={attemptToSignOut}>
            Sign Out
          </Button>
        </Link>
      </div>
    );
  }
  // case: signed in but not as a valid accountType (should be impossible)
  else {
    // returns nothing
    return null;
  }
}
