import React, { useState, useEffect, useRef } from "react";
import { Link, Redirect } from "react-router-dom";
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import TextField from "@material-ui/core/TextField";
import Container from "@material-ui/core/Container";
import Paper from "@material-ui/core/Paper";
import { Button } from "@material-ui/core";
import Axios from "axios";

export default function AddTeacherPage(props) {
  // This functions hadels key presses
  const useKey = (key, cb) => {
    const callBackRef = useRef(cb);
    useEffect(() => {
      callBackRef.current = cb;
    });
    useEffect(() => {
      const handle = (event) => {
        if (event.code === key) {
          callBackRef.current(event);
        }
      };
      document.addEventListener("keypress", handle);
      return () => document.removeEventListener("keypress", handle);
    }, [key]);
  };

  // State to know if entries are in error
  const [inError, setInError] = useState(() => {
    return false;
  });
  
  const changeError = (bo) => {
    setInError(bo);
  };

  // Callback Function
  const handleEnter = () => {
    checkSave();
  };
  // Handle enter
  useKey("Enter", handleEnter);
  // Props that are required to use this page correctly
  // Props needed: all account data to be edited, pages to load on button click, and to 'save' the edited data
  const { functModifyAppState } = props;

  const [firstName, setFname] = useState(() => {
    return "";
  });

  const fnameChange = (e) => {
    setFname(e.target.value);
    setInError(false);
    setAttempted(false);
  };

  const [lastName, setLname] = useState(() => {
    return "";
  });

  const lnameChange = (e) => {
    setLname(e.target.value);
    setInError(false);
    setAttempted(false);
  };

  const [phoneCell, setCellPhone] = useState(() => {
    return "";
  });

  const cellPhoneChange = (e) => {
    setCellPhone(e.target.value);
    setInError(false);
    setAttempted(false);
  };

  const [mail, setEmail] = useState(() => {
    return "";
  });

  const emailChange = (e) => {
    setEmail(e.target.value);
    setInError(false);
    setAttempted(false);
  };

  const [address, setStreet] = useState(() => {
    return "";
  });

  const streetChange = (e) => {
    setStreet(e.target.value);
    setInError(false);
    setAttempted(false);
  };

  const [town, setCity] = useState(() => {
    return "";
  });

  const cityChange = (e) => {
    setCity(e.target.value);
    setInError(false);
    setAttempted(false);
  };

  const [biggerTown, setState] = useState(() => {
    return "";
  });

  const stateChange = (e) => {
    setState(e.target.value);
    setInError(false);
    setAttempted(false);
  };

  const [zap, setZip] = useState(() => {
    return "";
  });

  const zipChange = (e) => {
    setZip(e.target.value);
    setInError(false);
    setAttempted(false);
  };

  const [bDay, setBirthDate] = useState(() => {
    return "";
  });

  const birthDateChange = (e) => {
    setBirthDate(e.target.value);
    setInError(false);
    setAttempted(false);
  };

  const [hireDate, setHireDate] = useState(() => {
    return "";
  });

  const hireDateChange = (e) => {
    setHireDate(e.target.value);
    setInError(false);
    setAttempted(false);
  };

  const [isPageReadyToBeRedirected, setRedirectState] = useState(() => {
    return false;
  });

  const changeRedirectState = () => {
    setRedirectState(true);
    setInError(false);
    setAttempted(false);
  };

  const [linkToRedirectTo, setLink] = useState(() => {
    return null;
  });

  const changeLink = (lin) => {
    setLink(lin);
    setInError(false);
    setAttempted(false);
  };

  const checkSave = () => {
    if (
      firstName !== "" &&
      lastName !== "" &&
      address !== "" &&
      town !== "" &&
      biggerTown !== "" &&
      zap !== "" &&
      mail !== "" &&
      phoneCell !== "" &&
      bDay !== "" &&
      hireDate !== ""
    ) {
      attemptSave();
      setAttempted(true);
    } else {
      setInError(true);
      setAttempted(true);
      console.log("Fill out the fields!!!");
    }
  };

  const attemptSave = () => {
    Axios({
      method: "POST",
      url: "http://localhost:5000/addTeacher",
      headers: {
        "Content-Type": "application/json",
      },
      data: {
        fname: firstName,
        lname: lastName,
        phone: phoneCell,
        email: mail,
        street: address,
        city: town,
        state: biggerTown,
        zip: zap,
        birthdate: bDay,
        hiredate: hireDate,
      },
    })
      .then((res) => {
        let teachers = res.data.teachers;
        //This is where the respones goes if necessary
        if (res.data.message === "success") {
          // teachers = teachers.map((teacher) => {
          //   teacher.birthdate = teacher.birthdate.toString().substring(0, 10);
          //   teacher.hiredate = teacher.hiredate.toString().substring(0, 10);
          // });
          functModifyAppState({ teachers: teachers });
          changeRedirectState();
          changeLink("/employee-dashboard");
        } else {
          console.log("AddTeacherPage.js in the toilet");
        }
      })
      .catch((error) => {
        console.log(error.data);
      });
  };

  const [attemptedSave, setAttempted] = useState(() => {
    return false;
  });

  // Get helper text for fields if in error
  const getHelper = (val) => {
    if (val === "" && attemptedSave && inError) {
      return "Required Field";
    } else if (attemptedSave && inError) {
      return "User Name or Password Incorrect";
    } else {
      return "";
    }
  };
  // Set fields to be in error conditionally
  const getError = (val) => {
    if (val === "" && attemptedSave && inError) {
      return inError;
    } else if (attemptedSave && inError) {
      return inError;
    } else {
      return inError;
    }
  };

  if (isPageReadyToBeRedirected === true && linkToRedirectTo != null) {
    return <Redirect to={linkToRedirectTo} />;
  }

  return (
    <div>
      <Container style={{ paddingTop: "6vh", paddingBottom: "4vh" }} maxWidth="md">
        <Paper
          elevation={10}
          style={{
            position: "relative",
            padding: "4vw",
            paddingBottom: "5vh",
          }}
        >
          <Typography variant="h6" gutterBottom>
            Add Teacher
          </Typography>
          <br></br>
          <Grid container spacing={3} gutterBottom>
            <Grid item xs={12} sm={6}>
              <TextField
                variant="outlined"
                id="firstName"
                name="firstName"
                label="First name"
                required
                value={firstName}
                onChange={fnameChange}
                fullWidth
                autoComplete="given-name"
                error={getError(firstName)}
                helperText={getHelper(firstName)}
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <TextField
                variant="outlined"
                id="lastName"
                name="lastName"
                label="Last name"
                required
                value={lastName}
                onChange={lnameChange}
                fullWidth
                autoComplete="family-name"
                error={getError(lastName)}
                helperText={getHelper(lastName)}
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <TextField
                variant="outlined"
                id="cellPhone"
                name="cellPhone"
                label="Cell"
                required
                value={phoneCell}
                onChange={cellPhoneChange}
                fullWidth
                autoComplete="cell-phone"
                error={getError(phoneCell)}
                helperText={getHelper(phoneCell)}
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <TextField
                variant="outlined"
                id="Email"
                name="emailAddress"
                label="Email"
                required
                value={mail}
                onChange={emailChange}
                fullWidth
                autoComplete="email-address"
                error={getError(mail)}
                helperText={getHelper(mail)}
              />
            </Grid>
            <Grid item xs={6}>
              <TextField
                variant="outlined"
                id="address"
                name="address"
                label="Address"
                value={address}
                onChange={streetChange}
                fullWidth
                autoComplete="shipping address-line1"
                error={getError(firstName)}
                helperText={getHelper(firstName)}
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <TextField
                variant="outlined"
                id="city"
                name="city"
                label="City"
                value={town}
                required
                onChange={cityChange}
                fullWidth
                autoComplete="shipping address-level2"
                error={getError(firstName)}
                helperText={getHelper(firstName)}
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <TextField
                variant="outlined"
                id="state"
                name="state"
                label="State/Province/Region"
                required
                value={biggerTown}
                onChange={stateChange}
                fullWidth
                error={getError(firstName)}
                helperText={getHelper(firstName)}
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <TextField
                variant="outlined"
                id="zip"
                name="zip"
                label="Zip / Postal code"
                required
                value={zap}
                onChange={zipChange}
                fullWidth
                autoComplete="shipping postal-code"
                error={getError(firstName)}
                helperText={getHelper(firstName)}
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <TextField
                InputLabelProps={{ shrink: true }}
                variant="outlined"
                id="birthday"
                name="birthday"
                label="Birthday"
                required
                value={bDay}
                onChange={birthDateChange}
                fullWidth
                autoComplete="birthday"
                type="date"
                error={getError(bDay)}
                helperText={getHelper(bDay)}
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <TextField
                InputLabelProps={{ shrink: true }}
                variant="outlined"
                id="hireDate"
                name="hireDate"
                label="Hire Date"
                required
                value={hireDate}
                onChange={hireDateChange}
                fullWidth
                autoComplete="hiredate"
                type="date"
                error={getError(hireDate)}
                helperText={getHelper(hireDate)}
              />
            </Grid>
          </Grid>
          <Grid item xs={12} style={{ paddingTop: "3vh" }}>
            <Button variant="contained" color="secondary" style={{ width: "6vw" }} onClick={checkSave}>
              Save
            </Button>
          </Grid>
          <Grid item xs={12} style={{ paddingTop: "3vh" }}>
            <Link style={{ textDecoration: "none" }} to="/account">
              <Button variant="contained" color="secondary" style={{ width: "6vw" }}>
                Cancel
              </Button>
            </Link>
          </Grid>
        </Paper>
      </Container>
    </div>
  );
}
