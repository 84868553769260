import React, { useState } from "react";
import { Redirect } from 'react-router-dom';
import Axios from "axios";
import { makeStyles } from "@material-ui/core/styles";
import { Typography, Stepper, Step, StepButton } from "@material-ui/core";

import AccCreWelcomeStep from "./AccCreWelcomeStep.js";
import AccCreAccountInformationStep from "./AccCreAccountInformationStep.js";
import AccCreGeneralPoliciesStep from "./AccCreGeneralPoliciesStep.js";
import AccCreDancePoliciesStep from "./AccCreDancePoliciesStep.js";
import AccCreDoneStep from "./AccCreDoneStep.js";

const useStyles = makeStyles({
  paper: {
    maxWidth: "-webkit-fill-available",
    minWidth: "-webkit-fill-available",
  },
  noMargin: {
    margin: "0px",
  },
});


export default function AccountCreationManagerPage(props){
  // styling
  const classes = useStyles();

  // state variables for redirecting the page only when ready
  // if the page is ready to be redirected (work successful | cancel work)
  // where to redirect to in each case
  const [pageRedirectState, setPageRedirectState] = useState({
    isPageReadyToBeRedirected: false,
    linkToRedirectTo: null
  });

  // LOCAL STEPPER
  // state variable for what step the stepper is on
  const [activeStepState, setActiveStepState] = useState(0);

  // state variable for which steps from the stepper have been completed
  const [completedState, setCompletedState] = useState(new Set());

  // state variable for determining what step the lower level stepper should start on
  const [startAtFirstStepState, setStartAtFirstStepState] = useState(true);

  // ACCOUNT INFORMATION
  // state variable for which steps from the stepper have been completed for ACCOUNT INFORMATION
  const [accInfCompletedState, setAccInfCompletedState] = useState(new Set());

  // state variable for when a step from the stepper has been skipped for ACCOUNT INFORMATION
  const [accInfSkippedState, setAccInfSkippedState] = useState(new Set());



  // GENERAL POLICIES
  // state variable for which steps from the stepper have been completed for ACCOUNT INFORMATION
  const [genPolCompletedState, setGenPolCompletedState] = useState(new Set());

  // state variable for when a step from the stepper has been skipped for ACCOUNT INFORMATION
  const [genPolSkippedState, setGenPolSkippedState] = useState(new Set());

  // DANCE POLICIES
  // state variable for which steps from the stepper have been completed for ACCOUNT INFORMATION
  const [danPolCompletedState, setDanPolCompletedState] = useState(new Set());

  // state variable for when a step from the stepper has been skipped for ACCOUNT INFORMATION
  const [danPolSkippedState, setDanPolSkippedState] = useState(new Set());

  
  // state variable for holding on to the Primary Contact Information
  const [primaryContactInformationState, setPrimaryContactInformationState] = useState({
    id: props.accountData.ID,
    fname: "",
    lname: "",
    cellPhone: "",
    homePhone: "",
    street: "",
    city: "",
    state: "",
    zip: ""
  });

  // state variable for holding on to the Additional Contact Information
  const [additionalContactInformationState, setAdditionalContactInformationState] = useState({
    fname: "",
    lname: "",
    cellPhone: "",
    homePhone: "",
    email: "",
    relation: ""
  });

  // state variable for holding on to the Student Information
  const [studentInformationState, setStudentInformationState] = useState({
    fname: "",
    lname: "",
    birthdate: "",
    medical: ""
  });

  // if the user has saved Additional Contact Information
  const [hasAdditionalContactInformationState, setHasAdditionalContactInformationState] = useState(false);

  // if the user has saved Student Information
  const [hasStudentInformationState, setHasStudentInformationState] = useState(false);


  // stepper step labels
  const steps = getSteps();

  // function that returns the list of steps as an array
  function getSteps(){
    return ["Welcome", "Account Information", "General Policies", "Dance Policies", "Done"];
  }

  // strings for button text
  const nextStepButtonString = "Accept & Continue";
  const saveStepButtonString = "Save & Continue";
  const skipStepButtonString = "Skip For Now";
  const previousStepButtonString = "Back";

  // function that return the content for each step
  function getStepContent(step){
    switch(step){
      // Welcome
      case 0:
        return <AccCreWelcomeStep 
          functTopHandleNext={handleNext}
        />;
      // Contact Information
      case 1:
        return <AccCreAccountInformationStep 
          accountData={props.accountData}
          functTopHandleNext={handleNext}
          functTopHandleBack={handleBack}
          nextStepButtonString={nextStepButtonString}
          saveStepButtonString={saveStepButtonString}
          skipStepButtonString={skipStepButtonString}
          previousStepButtonString={previousStepButtonString}
          startAtFirstStep={startAtFirstStepState}
          accInfCompletedState={accInfCompletedState}
          accInfSkippedState={accInfSkippedState}
          setAccInfCompletedState={setAccInfCompletedState}
          setAccInfSkippedState={setAccInfSkippedState}
          primaryContactInformationState={primaryContactInformationState}
          additionalContactInformationState={additionalContactInformationState}
          studentInformationState={studentInformationState}
          functSetPrimaryContactInformationState={setPrimaryContactInformationState}
          functSetAdditionalContactInformationState={setAdditionalContactInformationState}
          functSetStudentInformationState={setStudentInformationState}
          functSetHasAdditionalContactInformationState={setHasAdditionalContactInformationState}
          functSetHasStudentInformationState={setHasStudentInformationState}
        />;
      // General Policies
      case 2:
        return <AccCreGeneralPoliciesStep 
          functTopHandleNext={handleNext}
          functTopHandleBack={handleBack}
          nextStepButtonString={nextStepButtonString}
          previousStepButtonString={previousStepButtonString}
          startAtFirstStep={startAtFirstStepState}
          genPolCompletedState={genPolCompletedState}
          genPolSkippedState={genPolSkippedState}
          setGenPolCompletedState={setGenPolCompletedState}
          setGenPolSkippedState={setGenPolSkippedState}
        />;
      // Dance Policies
      case 3:
        return <AccCreDancePoliciesStep 
          functTopHandleNext={handleNext}
          functTopHandleBack={handleBack}
          nextStepButtonString={nextStepButtonString}
          previousStepButtonString={previousStepButtonString}
          startAtFirstStep={startAtFirstStepState}
          danPolCompletedState={danPolCompletedState}
          danPolSkippedState={danPolSkippedState}
          setDanPolCompletedState={setDanPolCompletedState}
          setDanPolSkippedState={setDanPolSkippedState}
        />;
      // Done
      case 4:
        return <AccCreDoneStep 
          functAttemptToCompleteAccountCreation={attemptToCompleteAccountCreation}
        />;
      // default case
      default:
        return null;
    }
  }

  // check to see if a step is optional
  // (step) is a 0-based number that represents what step you're on
  // what you're matching it to is the steps you want to be optional
  // ex: return step === 0; means that the 0th step is optional (the first step)
  // ex: return false; means that no steps are optional
  const isStepOptional = (step) => {
    return false;
  };

  // step forward
  const handleNext = () => {
    handleComplete();
    setStartAtFirstStepState(true);
    setActiveStepState((prevActiveStepState) => prevActiveStepState + 1);
  };

  // step backward
  const handleBack = () => {
    setStartAtFirstStepState(false);
    setActiveStepState((prevActiveStepState) => prevActiveStepState - 1);
  };

  // redundant?
  const handleStep = (step) => () => {
    setActiveStepState(step);
  };

  // handle completing a step
  const handleComplete = () => {
    const newCompleted = new Set(completedState);
    newCompleted.add(activeStepState);
    setCompletedState(newCompleted);
  };

  // check if a step has been completed
  const isStepComplete = (step) => {
    return completedState.has(step);
  };

  // called only from the Done step
  const attemptToCompleteAccountCreation = () => {
    // SCENARIOS:
    // 0: only primary data
    // 1: primary + addional
    // 2: primary + student
    // 3: all
    let scenario = 0;
    if(hasAdditionalContactInformationState === true && hasStudentInformationState === false){
      // 1: primary + addional
      scenario = 1;
    }
    else if(hasAdditionalContactInformationState === false && hasStudentInformationState === true){
      // 2: primary + student
      scenario = 2;
    }
    else if(hasAdditionalContactInformationState === true && hasStudentInformationState === true){
      // 3: all
      scenario = 3;
    }

    // DATABASE LADS DO STUFF HERE
    Axios({
      method: "POST",
      url: "http://localhost:5000/firstTime",
      headers: {
        "Content-Type": "application/json",
      },
      data: {
        scenario: scenario,
        primary: primaryContactInformationState,
        additional: additionalContactInformationState,
        student: studentInformationState,
      },
    }).then((res) => {
      if (res.data.message === "success") {
        // check scenario
        // 0: only primary data
        // 1: primary + addional
        // 2: primary + student
        // 3: all
        if(scenario === 0){
          let acctD = res.data.userData[0];
          acctD.accountType = "Customer";
          props.functModifyAppState({
            isSignedIn: true,
            accountData: acctD
          });
        }
        else if(scenario === 1){
          let acctD = res.data.userData[0];
          acctD.accountType = "Customer";
          props.functModifyAppState({
            isSignedIn: true,
            accountData: acctD,
            contacts: res.data.contactData
          });
        }
        else if(scenario === 2){
          let acctD = res.data.userData[0];
          acctD.accountType = "Customer";
          props.functModifyAppState({
            isSignedIn: true,
            accountData: acctD,
            students: res.data.studentData
          });
        }
        else if(scenario === 3){
          let acctD = res.data.userData[0];
          acctD.accountType = "Customer";
          props.functModifyAppState({
            isSignedIn: true,
            accountData: acctD,
            contacts: res.data.contactData,
            students: res.data.studentData
          });
        }

        // set that the page is ready to be redirected
        setPageRedirectState({
          isPageReadyToBeRedirected: true,
          linkToRedirectTo: "/account"
        });
      }
      else {
        console.log("Create Account failed");
      }
    });
  };

  // FAKE FUNCTION TO NOT HIT THE DB
  // called only from the Done step
  const fakeAttemptToCompleteAccountCreation = () => {
    // SCENARIOS:
    // 0: only primary data
    // 1: primary + addional
    // 2: primary + student
    // 3: all
    let scenario = 0;
    if(hasAdditionalContactInformationState === true && hasStudentInformationState === false){
      // 1: primary + addional
      scenario = 1;
    }
    else if(hasAdditionalContactInformationState === false && hasStudentInformationState === true){
      // 2: primary + student
      scenario = 2;
    }
    else if(hasAdditionalContactInformationState === true && hasStudentInformationState === true){
      // 3: all
      scenario = 3;
    }

    console.log("SCENARIO: " + scenario);

    // check scenario
    // 0: only primary data
    // 1: primary + addional
    // 2: primary + student
    // 3: all
    if(scenario === 0){
      props.functModifyAppState({
        accountData: primaryContactInformationState
      });
    }
    else if(scenario === 1){
      props.functModifyAppState({
        accountData: primaryContactInformationState,
        contacts: additionalContactInformationState
      });
    }
    else if(scenario === 2){
      props.functModifyAppState({
        accountData: primaryContactInformationState,
        students: studentInformationState
      });
    }
    else if(scenario === 3){
      props.functModifyAppState({
        accountData: primaryContactInformationState,
        contacts: additionalContactInformationState,
        students: studentInformationState
      });
    }

    // set that the page is ready to be redirected
    setPageRedirectState({
      isPageReadyToBeRedirected: true,
      linkToRedirectTo: "/account"
    });

  };




  // redirect if the page is ready to be redirected
  if (pageRedirectState.isPageReadyToBeRedirected === true && pageRedirectState.linkToRedirectTo != null) {
    return <Redirect to={pageRedirectState.linkToRedirectTo} />;
  }
  // render the page normally
  else {
    return (
      <div className={classes.root}>
        <Typography variant="h4">Account Creation</Typography>
        <Stepper alternativeLabel activeStep={activeStepState}>
          {steps.map((label, index) => {
            const stepProps = {};
            const buttonProps = {};
            if (isStepOptional(index)) {
              buttonProps.optional = <Typography variant="caption">Optional</Typography>;
            }
            return (
              <Step key={label} {...stepProps}>
                <StepButton
                  onClick={handleStep(index)}
                  completed={isStepComplete(index)}
                  {...buttonProps}
                >
                  {label}
                </StepButton>
              </Step>
            );
          })}
        </Stepper>
        {getStepContent(activeStepState)}
      </div>
    );
  }
}
