import React, { Component } from "react";
import { BrowserRouter as Router, Switch, Route, Redirect } from "react-router-dom";
import Axios from "axios";

import AccountCreatedPage from "./Components/AccountCreatedPage.js";
import AccountPage from "./Components/AccountPage.js";
import AddAnAccountContactPage from "./Components/AddAnAccountContactPage.js";
import BadTargetPage from "./Components/BadTargetPage.js";
import CreateAccountPage from "./Components/CreateAccountPage.js";
import EditAccountPage from "./Components/EditAccountPage.js";
import EditPasswordPage from "./Components/EditPasswordPage.js";
import EditStudentPage from "./Components/EditStudentPage.js";
import EmployeeDashboardPage from "./Components/EmployeeDashboardPage.js";
import Header from "./Components/Header.js";
import ResetPasswordPage from "./Components/ResetPasswordPage.js";
import ScheduleLessonsPage from "./Components/ScheduleLessonsPage.js";
import SignedOutPage from "./Components/SignedOutPage.js";
import SignInPage from "./Components/SignInPage.js";
import TeachersPage from "./Components/TeachersPage.js";
import WelcomePage from "./Components/WelcomePage.js";
import ConfirmEmailPage from "./Components/ConfirmEmailPage.js";
import EmailResetPassPage from "./Components/EmailResetPassPage.js";
import AccountCreationManagerPage from "./Components/AccountCreationManagerPage.js";
import AddStudentPage from "./Components/AddStudentPage.js";
import EditContactPage from "./Components/EditContactPage.js";
import AddTeacherPage from "./Components/AddTeacherPage.js";
import AddEmployeePage from "./Components/AddEmployeePage.js";

import TestSourcePage from "./Components/TestSourcePage.js";
import TestTargetPage from "./Components/TestTargetPage.js";

class App extends Component {
    constructor(props) {
        super(props);
        this.state = {
            debugMode: false, // false | true
            isSignedIn: false, // false | true
            accountData: {},
            students: [],
            contacts: [],
            teachers: [],
        };

        // bind your functions here
        this.modifyAppState = this.modifyAppState.bind(this);
        this.addTempStudent = this.addTempStudent.bind(this);
    }

    /////////////////////////////////////State Change Functions////////////////////////////////////////

    // generic function for modifying App's state data
    modifyAppState(inObj) {
        console.log("Modifying the state data of App");
        console.log("App State Before Change:");
        console.log(this.state);
        console.log("Incoming Object:");
        console.log(inObj);
        this.setState(inObj);
    }

    signIn = (blob) => {
        this.setState({
            accountData: blob.accountData,
            contacts: blob.accountData,
            students: blob.students,
            teachers: blob.teachers,
        });
    };

    changeSignIn = () => {
        console.log("Sign In State Changed");
        this.setState({
            isSignedIn: true,
        });
    };

    deleteContact = (contactID) => {
        console.log("Deleting Contact");
        this.setState({
            contacts: [...this.state.contacts.filter((cont) => cont.id !== contactID)],
        });
    };

    addStudent = (student) => {
        console.log("Adding Student");
        this.setState({
            students: [...student],
        });
    };

    editStudent = (student) => {
        console.log("Editing Student");
        this.setState({
            students: this.state.students.map((stu) => {
                if (stu.id === student.id) {
                    stu = student;
                }
            }),
        });
    };

    deleteStudent = (studentID) => {
        console.log("Deleting Student");
        this.setState({
            students: [...this.state.students.filter((stu) => stu.id !== studentID)],
        });
    };

    // add the new student to a temp variable (clipboard logic)
    // once registration is complete the temp is added to the state
    // database things happen, and the temp is cleared out (seperate function)
    addTempStudent = (Student) => {
        console.log("Adding Student to temp variable");
        this.setState({
            tempStudent: Student,
        });
    };

    ///////////////////////////////////////////////////////////////////////////////////////////////////

    // //really is going to change the sign in type first
    // attemptSignIn(username, password) {
    //     Axios({
    //         method: "GET",
    //         url: "http://localhost:5000/login",
    //         headers: {
    //             "Content-Type": "application/json",
    //         },
    //         params: {
    //             user: username,
    //             password: password,
    //         },
    //     }).then((res) => {
    //         console.log(res.data.studentData);
    //     });
    // }

    render() {
        // new version of devTest page testing
        // set debugMode to true in the state variables
        // put the component name of the page you want to debug as the component name in the Route with in the Switch
        // ex: <Route exact path="/" component={PageYouWantToTestPage} />
        if (this.state.debugMode === true) {
            return (
                <div style={{ textAlign: "center" }}>
                    <Router>
                        <Header
                            isSignedIn={this.state.isSignedIn}
                            accountType={this.state.accountData.accountType}
                            userName={this.state.accountData.fname}
                            functModifyAppState={this.modifyAppState}
                        />
                        <Switch>
                            <Route exact path="/">
                                <WelcomePage />
                            </Route>
                            <Route path="/*" render={() => <Redirect to="/" />} />
                        </Switch>
                    </Router>
                </div>
            );
        }
        // normal functionality below this point
        // case: not signed in
        if (this.state.isSignedIn === false) {
            return (
                <div className="App Not Signed In" style={{ textAlign: "center" }}>
                    {/* These wrapper <div>'s arround Header and MainContentManager */}
                    {/* Need to have the styling of just the area they occupy on the page */}
                    {/* This will be similar for each block bellow */}
                    <Router>
                        <div className="Header">
                            <Header
                                isSignedIn={this.state.isSignedIn}
                                accountType={this.state.accountData.accountType}
                                userName={this.state.accountData.fname}
                                functModifyAppState={this.modifyAppState}
                            />
                        </div>
                        <div className="Main Content Region">
                            <Switch>
                                <Route exact path="/" render={() => <Redirect to="/welcome" />} />
                                <Route path="/welcome">
                                    <WelcomePage />
                                </Route>
                                <Route path="/test-source-page">
                                    <TestSourcePage />
                                </Route>
                                <Route path="/test-target-page">
                                    <TestTargetPage />
                                </Route>
                                <Route path="/create-account">
                                    <CreateAccountPage functModifyAppState={this.modifyAppState} />
                                </Route>
                                <Route path="/account-created">
                                    <AccountCreatedPage userName={this.state.accountData.fname} />
                                </Route>
                                <Route exact path="/email-reset/:token" component={EmailResetPassPage} />
                                <Route exact path="/confirm-email/:token" component={ConfirmEmailPage} />
                                <Route path="/sign-in">
                                    <SignInPage functModifyAppState={this.modifyAppState} />
                                </Route>
                                <Route path="/reset-password">
                                    <ResetPasswordPage />
                                </Route>
                                <Route path="/signed-out">
                                    <SignedOutPage />
                                </Route>
                                <Route path="/404-page-not-found">
                                    <BadTargetPage isSignedIn={this.state.isSignedIn} accountType={this.state.accountData.accountType} />
                                </Route>
                                <Route path="/*" render={() => <Redirect to="/404-page-not-found" />} />
                            </Switch>
                        </div>
                    </Router>
                </div>
            );
        }
        // case: signed in as a Customer
        else if (this.state.isSignedIn === true && this.state.accountData.accountType === "Customer") {
            return (
                <div className="App Customer Signed In" style={{ textAlign: "center" }}>
                    <Router>
                        <div className="Header" style={{ minHeight: "fit-content", maxHeight: "fit-content" }}>
                            <Header
                                isSignedIn={this.state.isSignedIn}
                                accountType={this.state.accountData.accountType}
                                userName={this.state.accountData.fname}
                                functModifyAppState={this.modifyAppState}
                            />
                        </div>
                        <div className="Main Content Region">
                            <Switch>
                                <Route exact path="/" render={() => <Redirect to="/account" />} />
                                <Route path="/create-account">
                                    <CreateAccountPage accountData={this.state.accountData} functModifyAppState={this.modifyAppState} />
                                </Route>
                                <Route path="/account-created">
                                    <AccountCreatedPage userName={this.state.accountData.fname} />
                                </Route>
                                <Route path="/sign-in">
                                    <SignInPage functModifyAppState={this.modifyAppState} />
                                </Route>
                                <Route path="/signed-out">
                                    <SignedOutPage />
                                </Route>
                                <Route path="/account">
                                    <AccountPage
                                        accountData={this.state.accountData}
                                        contacts={this.state.contacts}
                                        students={this.state.students}
                                        functModifyAppState={this.modifyAppState}
                                    />
                                </Route>
                                <Route path="/complete-account-creation">
                                    <AccountCreationManagerPage accountData={this.state.accountData} functModifyAppState={this.modifyAppState} />
                                </Route>
                                <Route path="/edit-account">
                                    <EditAccountPage accountData={this.state.accountData} functModifyAppState={this.modifyAppState} />
                                </Route>
                                <Route path="/edit-password">
                                    <EditPasswordPage accountData={this.state.accountData} functModifyAppState={this.modifyAppState} />
                                </Route>
                                <Route path="/add-student">
                                    <AddStudentPage accountData={this.state.accountData} functModifyAppState={this.modifyAppState} />
                                </Route>
                                <Route path="/edit-student">
                                    <EditStudentPage studentData={this.state.students} functModifyAppState={this.modifyAppState} />
                                </Route>
                                <Route path="/edit-contact">
                                    <EditContactPage contacts={this.state.contacts} functModifyAppState={this.modifyAppState} />
                                </Route>
                                <Route path="/add-an-account-contact">
                                    <AddAnAccountContactPage accountData={this.state.accountData} functModifyAppState={this.modifyAppState} />
                                </Route>
                                <Route path="/schedule-lessons">
                                    <ScheduleLessonsPage />
                                </Route>
                                <Route path="/teachers">
                                    <TeachersPage functModifyAppState={this.modifyAppState} />
                                </Route>
                                <Route path="/404-page-not-found">
                                    <BadTargetPage isSignedIn={this.state.isSignedIn} accountType={this.state.accountData.accountType} />
                                </Route>
                                <Route path="/*" render={() => <Redirect to="/404-page-not-found" />} />
                            </Switch>
                        </div>
                    </Router>
                </div>
            );
        }
        // case: signed in as an Employee
        else if (this.state.isSignedIn === true && this.state.accountData.accountType === "Employee") {
            return (
                <div className="App Employee Signed In" style={{ textAlign: "center" }}>
                    <Router>
                        <div className="Header">
                            <Header
                                isSignedIn={this.state.isSignedIn}
                                accountType={this.state.accountData.accountType}
                                userName={this.state.accountData.fname}
                                functModifyAppState={this.modifyAppState}
                            />
                        </div>
                        <div className="Main Content Region">
                            <Switch>
                                <Route exact path="/" render={() => <Redirect to="/employee-dashboard" />} />
                                <Route path="/sign-in">
                                    <SignInPage functModifyAppState={this.modifyAppState} />
                                </Route>
                                <Route path="/signed-out">
                                    <SignedOutPage />
                                </Route>
                                <Route path="/employee-dashboard">
                                    <EmployeeDashboardPage
                                        users={this.state.users}
                                        contacts={this.state.contacts}
                                        students={this.state.students}
                                        teachers={this.state.teachers}
                                        employees={this.state.employees}
                                    />
                                </Route>
                                <Route path="/add-teacher">
                                    <AddTeacherPage functModifyAppState={this.modifyAppState} />
                                </Route>
                                {/* <Route path="/employee-dashboard/:tableType">
                  <EmployeeDashboardPage />
                </Route> */}
                                <Route path="/404-page-not-found">
                                    <BadTargetPage isSignedIn={this.state.isSignedIn} accountType={this.state.accountData.accountType} />
                                </Route>
                                <Route path="/*" render={() => <Redirect to="/404-page-not-found" />} />
                            </Switch>
                        </div>
                    </Router>
                </div>
            );
        }
        // case: signed in but not as a valid accountType (should be impossible)
        // BadTargetPage can reset the App to a default state should this case somehow be reached
        else {
            return (
                <div style={{ textAlign: "center" }}>
                    <Router>
                        <div style={{ minHeight: "fit-content", maxHeight: "fit-content" }}>
                            <Header
                                isSignedIn={this.state.isSignedIn}
                                accountType={this.state.accountData.accountType}
                                userName={this.state.accountData.fname}
                            />
                        </div>
                        <Switch>
                            <Route exact path="/" render={() => <Redirect to="/404-page-not-found" />} />
                            <Route path="/404-page-not-found">
                                <BadTargetPage
                                    isSignedIn={this.state.isSignedIn}
                                    accountType={this.state.accountData.accountType}
                                    functModifyAppState={this.modifyAppState}
                                />
                            </Route>
                            <Route path="/*" render={() => <Redirect to="/404-page-not-found" />} />
                        </Switch>
                    </Router>
                </div>
            );
        }
    }
}

export default App;
